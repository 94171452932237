import Parse from "parse";

const saveUUIDToWebCode = (uuid) => {
  try {
    return new Promise((resolve, reject) => {
      var WebCode = Parse.Object.extend("WebCode");
      var wc = new WebCode();
      wc.set("uuid", uuid);
      wc.save().then(
        (result) => {
          if (result) {
            resolve(result);
          }
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export { saveUUIDToWebCode };
