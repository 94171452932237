import * as actions from "./actionTypes";

const initialState = {
  groupMapObjects: undefined,
  isLoadingGroupMap: true,
};

const GroupMap = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING:
      state = { ...state, isLoadingGroupMap: action.payload.boolValue };
      break;

    case actions.UPDATE_GROUPMAPS_FOR_USERID:
      state = {
        ...state,
        groupMapObjects: action.payload.groupMapObjects,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GroupMap;
