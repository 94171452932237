import * as actions from "./actionTypes";

const initialState = {
  onlineLearningSessionObjects: [],
  isLoadingOLS: true,
};

const OnlineLearningSession = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING:
      state = { ...state, isLoadingOLS: action.payload.boolValue };
      break;

    case actions.UPDATE_ONLINE_LEARNING_SESSION_FOR_KID_IDS:
      state = {
        ...state,
        onlineLearningSessionObjects:
          action.payload.onlineLearningSessionObjects,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default OnlineLearningSession;
