import Parse from "parse";

const getTeacherForObjectId = (objectId) => {
  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("Teacher");
      query.equalTo("objectId", objectId);
      query.notEqualTo("deleted",true)
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export { getTeacherForObjectId };
