import Parse from "parse";

const getRemindersForLiveMeeting = (
  kidId,
  classId,
  schoolId,
  groupIds,
  familyId
) => {
  try {
    return new Promise((resolve, reject) => {
      let currentDate = new Date();
      let startTime = new Date(currentDate.setHours(0, 0, 0, 0));
      let endTime = new Date(currentDate.setHours(23, 59, 59, 999));

      var query1 = new Parse.Query("Reminder");
      query1.equalTo("kidId", kidId);
      query1.equalTo("eventType", 3);
      var query2 = new Parse.Query("Reminder");
      query2.equalTo("classId", classId);
      query2.equalTo("eventType", 3);
      var query3 = new Parse.Query("Reminder");
      query3.equalTo("schoolId", schoolId);
      query3.equalTo("eventType", 3);

      if (groupIds.length > 0) {
        var query4 = new Parse.Query("Reminder");
        query4.containedIn("groupId", groupIds);
        query4.equalTo("eventType", 3);
      }
      if (familyId) {
        var query5 = new Parse.Query("Reminder");
        query5.equalTo("familyId", familyId);
        query5.equalTo("eventType", 3);
      }
      var query = new Parse.Query("Reminder");
      if (query4 && query5) {
        query = Parse.Query.or(query1, query2, query3, query4, query5);
      } else if (query4) {
        query = Parse.Query.or(query1, query2, query3, query4);
      } else if (query5) {
        query = Parse.Query.or(query1, query2, query3, query5);
      }

      query.lessThanOrEqualTo("reminderTime", endTime);
      query.greaterThanOrEqualTo("reminderTime", startTime);

      query.notEqualTo("isDeleted", true);
      query.notEqualTo("status", false);
      query.find().then(
        (results) => {
          resolve(results);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export { getRemindersForLiveMeeting };
