import * as actions from "./actionTypes";

const initialState = {
  profileKids: undefined,
  userObjectKid: undefined,
  isLoadingKids: true,
};

const Kid = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING:
      state = { ...state, isLoadingKids: action.payload.boolValue };
      break;

    case actions.UPDATE_PROFILE_KIDS:
      state = {
        ...state,
        profileKids: action.payload.kids,
      };
      break;
    case actions.UPDATE_KID:
      state = {
        ...state,
        userObjectKid: action.payload.kidList,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Kid;
