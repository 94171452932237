import Parse from 'parse';

export const parseLoginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      
      
        Parse.User.logIn(email, password)
        .then((user) => {
            resolve({type:"Success",user:user});
        
        }, (error) => {
            resolve({ type:"Error",message:error.message });
        })
    });
};

export const parseLogoutUser = () => {
    return new Promise((resolve, reject) => {
        Parse.User.logOut().then(() => {
            Parse.Object.unPinAllObjects().then(() => {
                resolve();
            },(error) => {
                resolve(error.message);
            });
        }, (error) => {
            resolve(error.message);
          });
      });
}

export const  getUser = () => {
    try {

        return new Promise((resolve, reject) => {
            if (Parse.User.current() ) {
                if (Parse.User.current().attributes.teacherId) {
                    var Teacher = Parse.Object.extend("Teacher");
                    var query = new Parse.Query(Teacher);
                    query.get(Parse.User.current().attributes.teacherId).then(
                        (result) => {
                            resolve(result);
                        },
                        (error) => {
                            resolve(null);
                        }
                      );
                }
            }
          });	
	} catch (error) { }
}


export const forgoPassword = (email) => {
    return new Promise((resolve, reject) => {
        try {
            Parse.User.requestPasswordReset(email)
                .then( (success) =>{
                    console.log(success);
                    resolve({ type: "success", message: "Password reset request was sent successfully, please check your email for password reset link ",object:success });
                }).catch( (error) =>{
                    resolve({ type: "error", message: "Error: " + error.code + " " + error.message });
                });
          } catch (e) {
  
          }
      });
   
}

export const  registerUserParse = (userEmail,password) => {
    try {

        return new Promise((resolve, reject) => {
            var user = new Parse.User();
            user.set("password", password);
            user.set("email", userEmail);
            user.set("username", userEmail);
            try {
            user.signUp().then((user) => {
                if (user) {
                    resolve({user:user,responce:"success"})
                }
            })
            .catch((error) => { 
                if (error.code == 202) {
                    resolve( "taken");
                } else {
                    resolve( "wrong");
                }
            });
            } catch (error) {
                resolve("Error: " + error.code + " " + error.message);
            }
          });	
	} catch (error) { }
}

