import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as KidHelper from "../../helpers/ParseHelpers/kidHelper";
import * as actionTypes from "./actionTypes";
import * as KidAction from "./actions";

function* getKidsForFamilyIdSaga({ payload: { familyId } }) {
  try {
    const response = yield call(KidHelper.getKidsForFamilyId, familyId);
    yield put(KidAction.setLoading(false));

    if (response) {
      yield put(KidAction.updateProfileKids(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getProfileKidForObjectIdSaga({ payload: { objectId } }) {
  try {
    const response = yield call(KidHelper.getProfileKidForObject, objectId);
    yield put(KidAction.setLoading(false));

    if (response) {
      yield put(KidAction.updateProfileKids(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getKidForObjectIdSaga({ payload: { objectId } }) {
  try {
    const response = yield call(KidHelper.getKidForObjectId, objectId);
    yield put(KidAction.setLoading(false));

    if (response) {
      yield put(KidAction.updateKid(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetKidsForFamilyIdSaga() {
  yield takeEvery(actionTypes.GET_KIDS_FOR_FAMILY_ID, getKidsForFamilyIdSaga);
}
export function* watchGetKidForObjectIdSaga() {
  yield takeEvery(actionTypes.GET_KID_FOR_OBJECT_ID, getKidForObjectIdSaga);
}
export function* watchGetProfileKidForObjectIdSaga() {
  yield takeEvery(actionTypes.GET_PROFILE_KID_FOR_OBJECT_ID, getProfileKidForObjectIdSaga);
}


function* kidSaga() {
  yield all([
    fork(watchGetKidsForFamilyIdSaga),
    fork(watchGetKidForObjectIdSaga),
    fork(watchGetProfileKidForObjectIdSaga),
  ]);
}
export default kidSaga;
