import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardImg,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as Constants from "../../Constants";
import { withTranslation } from "react-i18next";
import { matchPath } from "react-router";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import QRCode from "qrcode";
import { connect } from "react-redux";

import Parse from "parse";
import userImage from "../../assets/images/users/user.png";
import { getKidsForFamilyId } from "../../store/actions";

import { v4 as uuidv4 } from "uuid";

const ChooseProfile = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/learningChooseProfile/:familyId",
    exact: true,
    strict: false,
  });

  const familyId = match ? match.params.familyId : "";

  const [loading, setLoading] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(true);
  const [profileList, setProfileList] = useState([]);

  useEffect(() => {
    if (props.profileKids) {
      let kids = props.profileKids;
      let tempProfileList = [];
      for (var i in kids) {
        var temp = {};
        temp.id = kids[i].id;
        temp.name = kids[i].attributes.Name;
        temp.parseObject = kids[i];
        if (kids[i].attributes.Photo) {
          temp.photoURL = kids[i].attributes.Photo._url;
        } else {
          if (kids[i].attributes.Gender == "Male") {
            //temp.photoURL = "images/he.png";
          } else {
            //temp.photoURL = "images/she.png";
          }
        }
        tempProfileList.push(temp);
      }
      setProfileList(tempProfileList);
    }
  }, [props.profileKids]);

  useEffect(() => {
    if (familyId) {
      props.getKidsForFamilyId(familyId);
    }
  }, [familyId]);

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <Modal isOpen={isModelOpen} fade={false} scrollable={true}>
          <ModalHeader charCode="">Choose Profile</ModalHeader>
          <ModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <center>
                <Row>
                  {profileList.map((profile, key) => (
                    <Col>
                      <div
                        style={{
                          width: "200px",
                          height: "200px",
                          backgroundColor: "rgb(255,255,255)",
                        }}
                        key={key}
                        className="mr-3"
                      >
                        <Link
                          to={
                            "/learningActivityView/" +
                            profile.parseObject.attributes.SchoolID +
                            "/" +
                            profile.id
                          }
                          onClick={() => {
                            localStorage.setItem(
                              Constants.SELECTED_KID_ID,
                              profile.id
                            );
                          }}
                          className="mt-1"
                          style={{ fontSize: "19px" }}
                        >
                          <img
                            className="mt-4  rounded-circle"
                            src={profile.photoURL ?? userImage}
                            style={{
                              width: "120px",
                              height: "120px",
                              textAlign: "center",
                            }}
                          ></img>

                          <br />

                          <p className="mt-3">{profile.name}</p>
                        </Link>
                      </div>
                    </Col>
                  ))}
                </Row>
              </center>
            </div>
          </ModalBody>
          {/* <ModalFooter
            style={{ backgroundColor: "rgb(242,242,242)" }}
          ></ModalFooter> */}
        </Modal>
      </Container>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { profileKids } = state.kid;

  return {
    profileKids,
  };
};

export default withTranslation()(
  withRouter(
    connect(mapStatetoProps, {
      getKidsForFamilyId,
    })(ChooseProfile)
  )
);
