import * as actions from "./actionTypes";

export const setLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const getFilesForFolderIds = (folderIds) => {
  return {
    type: actions.GET_FILES_FOR_FOLDER_IDS,
    payload: { folderIds },
  };
};

export const updateFilesForFolderIds = (fileObjects) => {
  return {
    type: actions.UPDATE_FILES_FOR_FOLDER_IDS,
    payload: { fileObjects },
  };
};
