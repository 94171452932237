import * as actions from "./actionTypes";

export const setLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const getTeacherForObjectId = (objectId) => {
  return {
    type: actions.GET_TEACHER_FOR_OBJECT_ID,
    payload: { objectId },
  };
};

export const updateUserObjectTeacher = (userObjectTeacher) => {
  return {
    type: actions.UPDATE_USER_OBJECT_TEACHER,
    payload: { userObjectTeacher },
  };
};
