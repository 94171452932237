import Parse from "parse";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import PerfectScrollbar from "react-perfect-scrollbar";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import classnames from "classnames";
import Moment from "moment";

//Import Icons
import FeatherIcon from "feather-icons-react";
const CourseContent = (props) => {
  const [selectedActivity, setSelectedActivity] = useState(
    props.selectedActivity
  );
  const [selectedRowForCollapse, setSelectedRowForCollapse] = useState(false);
  const [upcommingSession, setUpcommingSession] = useState(false);
  const [selectedResource, setSelectedResource] = useState(
    props.selectedResource
  );
  const [selectedMeeting, setSelectedMeeting] = useState(props.selectedMeeting);
  const [
    selectedActivityForComplete,
    setSelectedActivityForComplete,
  ] = useState(undefined);
  const [isKid, setIsKid] = useState(false);

  const [activeTab, setActiveTab] = useState("1");
  const [completeButtonAlert, setCompleteButtonAlert] = useState(false);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    setIsKid(props.isKid);
  }, [props.isKid]);

  useEffect(() => {
    setSelectedActivity(props.selectedActivity);
  }, [props.selectedActivity]);
  useEffect(() => {
    setSelectedResource(props.selectedResource);
  }, [props.selectedResource]);

  const audioFormats = [
    ".mp3",
    ".MP3",
    ".wma",
    ".WMA",
    ".aac",
    ".AAC",
    ".wav",
    ".WAV",
    ".flac",
    ".FLAC",
    ".m4a",
    ".M4A",
  ];

  // useEffect(()=>{

  // },[])

  const getUpcomingSession = () => {
    var selected = false;

    if (
      props.courseObject &&
      (props.courseObject.attributes.mode === "LiveClass+Recorded" ||
        props.courseObject.attributes.mode === "LiveClass")
    ) {
      for (var i in props.learningActivities) {
        for (var j in props.learningActivities[i].activities) {
          if (
            props.learningActivities[i].activities[j].meeting !== null &&
            props.learningActivities[i].activities[j].meeting !== undefined
          ) {
            if (
              props.learningActivities[i].activities[j].meeting.attributes
                .meetingDate != null &&
              isToday(
                props.learningActivities[i].activities[j].meeting.attributes
                  .meetingDate
              )
            ) {
              // this.setState({
              //   selectedColumn: this.props.learningActivities[i].id,
              //   selectedActivity: this.props.learningActivities[i].activities[
              //     j
              //   ],
              //   upcommingSession: true,
              // });

              // this.onClickActivity(
              //   this.props.learningActivities[i].activities[j]
              // );
              selected = true;
              break;
            }
          }
        }
      }
    }

    if (
      props.learningActivities &&
      props.learningActivities.length > 0 &&
      !selected
    ) {
      var activity = null;
      var selectedColumn = "";
      for (var i in props.learningActivities) {
        if (
          props.learningActivities[i].activities &&
          props.learningActivities[i].activities.length > 0
        ) {
          activity = props.learningActivities[i].activities[0];
          selectedColumn = props.learningActivities[i].id;
          break;
        }
      }

      // this.setState({
      //   selectedColumn: selectedColumn,
      //   selectedActivity: activity,
      //   upcommingSession: true,
      // });
      // this.onClickActivity(activity);
    }
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  const onSelectRow = (activity) => {
    if (selectedRowForCollapse === activity.id) {
      setSelectedRowForCollapse(false);
    } else {
      setSelectedRowForCollapse(activity.id);
    }
  };

  const onClickActivity = (activity) => {
    setSelectedActivity(activity);
    props.onClickActivity(activity);
  };
  const onClickResource = (resource) => {
    setSelectedResource(resource);
    props.onClickResource(resource);
  };
  const onClickMeeting = (meetingObject) => {
    setSelectedMeeting(meetingObject);
    props.onClickMeeting(meetingObject);
  };

  const changeBackground = (e) => {
    e.target.style.background = "lightgray";
  };
  const changeToDefaultBackground = (e) => {
    e.target.style.background = "white";
  };

  const getFileTypeIcon = (resourceFile) => {
    var returnValue = "mdi mdi-file-document text-primary";

    if (
      resourceFile.url.includes(".png") ||
      resourceFile.url.includes(".jpeg") ||
      resourceFile.url.includes(".jpg")
    ) {
      returnValue = "mdi mdi-image text-success";
    } else if (
      resourceFile.type === "url" &&
      (resourceFile.url.includes("vimeo.com") ||
        resourceFile.url.includes("youtube.com") ||
        resourceFile.url.includes("youtu"))
    ) {
      returnValue = "mdi mdi-play-circle-outline text-danger";
    } else if (
      resourceFile.type === "vimeo" ||
      resourceFile.type === "youtube"
    ) {
      returnValue = "mdi mdi-play-circle-outline text-danger";
    } else if (
      resourceFile.name &&
      (resourceFile.name.includes(".mp3") ||
        resourceFile.name.includes(".MP3") ||
        resourceFile.name.includes(".wma") ||
        resourceFile.name.includes(".WMA") ||
        resourceFile.name.includes(".aac") ||
        resourceFile.name.includes(".AAC") ||
        resourceFile.name.includes(".wav") ||
        resourceFile.name.includes(".WAV") ||
        resourceFile.name.includes(".FLAC") ||
        resourceFile.name.includes(".flac") ||
        resourceFile.name.includes(".m4a") ||
        resourceFile.name.includes(".M4A"))
    ) {
      returnValue = "mdi mdi-music text-info";
    } else if (resourceFile.type === "quiz") {
      returnValue = "mdi mdi-playlist-edit text-info";
    }

    return returnValue;
  };
  const getFileTypeInfo = (resourceFile) => {
    var returnValue = "File/pdf";

    if (
      resourceFile.url.includes(".png") ||
      resourceFile.url.includes(".jpeg") ||
      resourceFile.url.includes(".jpg")
    ) {
      returnValue = "Image";
    } else if (
      resourceFile.type === "url" &&
      (resourceFile.url.includes("vimeo.com") ||
        resourceFile.url.includes("youtube.com") ||
        resourceFile.url.includes("youtu"))
    ) {
      returnValue = "Video";
    } else if (
      resourceFile.type === "vimeo" ||
      resourceFile.type === "youtube"
    ) {
      returnValue = "Video";
    } else if (
      resourceFile.name &&
      (resourceFile.name.includes(".mp3") ||
        resourceFile.name.includes(".MP3") ||
        resourceFile.name.includes(".wma") ||
        resourceFile.name.includes(".WMA") ||
        resourceFile.name.includes(".aac") ||
        resourceFile.name.includes(".AAC") ||
        resourceFile.name.includes(".wav") ||
        resourceFile.name.includes(".WAV") ||
        resourceFile.name.includes(".FLAC") ||
        resourceFile.name.includes(".flac") ||
        resourceFile.name.includes(".m4a") ||
        resourceFile.name.includes(".M4A"))
    ) {
      returnValue = "Audio";
    } else if (resourceFile.type === "quiz") {
      returnValue = "Quiz";
    }

    return returnValue;
  };
  // getActivityInfo = (activity) => {
  //   if (activity.fileType === "video") {
  //     return "Video";
  //   } else if (activity.fileType === "image") {
  //     return "Image";
  //   } else if (activity.fileType === "audio") {
  //     return "Audio file";
  //   } else if (activity.fileType === "meeting") {
  //     if (
  //       activity.meeting !== null &&
  //       activity.meeting.attributes.meetingDate !== null
  //     ) {
  //       return (
  //         "Live class is on: " +
  //         activity.meeting.attributes.meetingDate.toString().slice(0, 15)
  //       );
  //     } else {
  //       return "Live Class detailes not yet updated";
  //     }
  //   } else {
  //     return "File/pdf";
  //   }
  // };

  const onClickSomeThing = (activity, event) => {
    console.log(event.target.id);
  };

  return (
    <Container className="p-0">
      {completeButtonAlert && (
        <SweetAlert
          title=""
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnText="No"
          confirmBtnText="Yes"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            props.handleOnClickComplete(selectedActivityForComplete);
            setCompleteButtonAlert(false);
          }}
          onCancel={() => {
            setCompleteButtonAlert(false);
          }}
        >
          {"Do you want to mark this activity as completed?"}
        </SweetAlert>
      )}
      <div className="align-items-center border">
        <Nav pills justified>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggleTab("1");
              }}
            >
              <span>Home Learning</span>
              <i
                className="mdi mdi-replay p-2 font-size-16 "
                onClick={() => {
                  props.reloadHomeLearning();
                }}
              />
            </NavLink>
          </NavItem>
          {isKid && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2");
                }}
              >
                <span>Live Classes</span>
                <i
                  className="mdi mdi-replay p-2 font-size-16 "
                  onClick={() => {
                    props.reloadLiveMeeting();
                  }}
                />
              </NavLink>
            </NavItem>
          )}
          {/* <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "3",
              })}
              onClick={() => {
                toggleTab("3");
              }}
            >
              <i className="bx bx-book-content font-size-20 d-sm-none" />
              <span className="d-none d-sm-block">Exams</span>
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab={activeTab} className="mb-0 mt-2">
          <TabPane tabId="1">
            <PerfectScrollbar style={{ height: "48.5em" }}>
              {props.learningActivities.map((activity, key) => (
                <div
                  key={activity.id}
                  style={{ borderBottom: "3px solid white" }}
                >
                  <Link
                    onClick={() => {
                      onSelectRow(activity);
                      onClickActivity(activity);
                    }}
                    className={
                      selectedRowForCollapse &&
                      selectedRowForCollapse === activity.id
                        ? "faq position-relative text-primary"
                        : "faq position-relative text-dark"
                    }
                  >
                    <CardHeader
                      className=" bg-light alert—check "
                      id="headingOne"
                    >
                      {/* <h6 style={{ fontWeight: "900" }}>
                        {" "}
                        {activity.title}
                        <i
                          className={
                            selectedRowForCollapse &&
                            selectedRowForCollapse === activity.id
                              ? "mdi mdi-chevron-up float-right"
                              : "mdi mdi-chevron-down float-right"
                          }
                        ></i>
                      </h6> */}
                      {/* <div className="overflow-hidden me-auto">
                        <h5 className="font-size-13 text-truncate mb-1">
                          {activity.title}
                          <i
                            className={
                              selectedRowForCollapse &&
                              selectedRowForCollapse === activity.id
                                ? "mdi mdi-chevron-up float-right"
                                : "mdi mdi-chevron-down float-right"
                            }
                          ></i>
                        </h5>

                        {/* <p className="text-muted text-truncate mb-0">
                                          {getFileTypeInfo(resourceFile)}
                                        </p> 

                        <div className="ml-2 float-right">
                          <p className="text-muted">
                            {Moment(activity.time).format("DD/MM/YYYY")}
                          </p>
                        </div>
                      </div> */}
                      <div className="">
                        <div className="d-flex">
                          <div className="overflow-hidden me-auto">
                            <h6
                              className="font-size-13 text-truncate mb-1"
                              style={{ fontWeight: "900" }}
                            >
                              {activity.title}
                              <i
                                className={
                                  selectedRowForCollapse &&
                                  selectedRowForCollapse === activity.id
                                    ? "mdi mdi-chevron-up float-right"
                                    : "mdi mdi-chevron-down float-right"
                                }
                              ></i>
                            </h6>
                          </div>

                          <div className="ml-2">
                            <p className="text-muted">
                              {Moment(activity.time).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </CardHeader>
                  </Link>
                  {isKid && (
                    <div className="bg-light">
                      {activity.ratingValue != 100 ? (
                        <label
                          className="text-warning"
                          style={{
                            cursor: "pointer",
                            fontSize: "13px",
                            marginLeft: "20px",
                            marginRight: "10px",
                            marginTop: "",
                          }}
                          onClick={() => {
                            setCompleteButtonAlert(true);
                            setSelectedActivityForComplete(activity);
                          }}
                        >
                          {"Complete"}
                        </label>
                      ) : (
                        <label
                          className="text-success"
                          style={{
                            cursor: "pointer",
                            fontSize: "13px",
                            marginLeft: "20px",
                            marginRight: "10px",
                            marginTop: "",
                          }}
                          onClick={() => {
                            toastr.success(
                              "This activity is already marked as completed",
                              { timeOut: 1000 }
                            );
                          }}
                        >
                          {"Completed"}
                        </label>
                      )}
                    </div>
                  )}
                  <Collapse
                    isOpen={
                      selectedRowForCollapse &&
                      selectedRowForCollapse === activity.id
                        ? true
                        : false
                    }
                  >
                    <Card className="">
                      <CardBody>
                        <div className="mt-0">
                          {activity.contentList &&
                            activity.contentList.map((resourceFile, key) => (
                              <Card
                                className={
                                  selectedResource &&
                                  resourceFile.id === selectedResource.id
                                    ? "border border-primary shadow-none mb-2"
                                    : "border shadow-none mb-2"
                                }
                                key={resourceFile.id}
                              >
                                <div
                                  className="text-body"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    onClickResource(resourceFile);
                                  }}
                                >
                                  <div className="p-2">
                                    <div className="d-flex">
                                      <div className="avatar-xs align-self-center me-2">
                                        <div className="avatar-title rounded bg-transparent font-size-20">
                                          <i
                                            className={getFileTypeIcon(
                                              resourceFile
                                            )}
                                          ></i>
                                        </div>
                                      </div>

                                      <div className="overflow-hidden me-auto">
                                        <h5 className="font-size-13 text-truncate mb-1">
                                          {resourceFile.name}
                                        </h5>
                                        <p className="text-muted text-truncate mb-0">
                                          {getFileTypeInfo(resourceFile)}
                                        </p>
                                      </div>

                                      <div className="ml-2">
                                        <p className="text-muted">
                                          {Moment(
                                            resourceFile.parseObject.createdAt
                                          ).format("DD/MM/YYYY")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          {(activity.contentList == undefined ||
                            activity.contentList.length === 0) && (
                            <p className="text-muted">No resources</p>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Collapse>

                  {/* {isKid && (
                    <div>
                      <label
                        className="text-warning"
                        style={{
                          cursor: "pointer",

                          fontSize: "13px",
                          marginLeft: "20px",
                          marginRight: "10px",
                          marginTop: "5px",
                        }}
                        onClick={() => {}}
                      >
                        {"Complete"}
                      </label>

                      {/* <label
                        className="text-success"
                        style={{
                          cursor: "pointer",

                          fontSize: "13px",
                        }}
                        onClick={() => {
                          toastr.warning("kindly check in mobile app", {
                            timeOut: 1000,
                          });
                        }}
                      >
                        {"Submissions"}
                      </label> 
                    </div>
                  )} */}
                </div>
              ))}
              {(props.learningActivities == undefined ||
                props.learningActivities.length === 0) && (
                <Card className="">
                  <CardBody>
                    <div className="text-center">
                      <p>No content available</p>
                    </div>
                  </CardBody>
                </Card>
              )}
            </PerfectScrollbar>
          </TabPane>

          {isKid && (
            <TabPane tabId="2">
              <PerfectScrollbar>
                <Card className="">
                  <CardBody>
                    <div className="mt-0">
                      {props.meetingDetails &&
                        props.meetingDetails.map((meetingObject, key) => (
                          <Card
                            className={
                              selectedMeeting &&
                              meetingObject.id === selectedMeeting.id
                                ? "border border-primary shadow-none mb-2"
                                : "border shadow-none mb-2"
                            }
                            key={meetingObject.id}
                          >
                            <div
                            style={{
                              cursor: "pointer",
                              
                            }}
                              className="text-body"
                              onClick={(event) => {
                                event.preventDefault();
                                onClickMeeting(meetingObject);
                              }}
                            >
                              <div className="p-2">
                                <div className="d-flex">
                                  <div className="avatar-xs align-self-center me-2">
                                    <div className="avatar-title rounded bg-transparent font-size-20">
                                      <i
                                        className={
                                          "mdi mdi-video-outline text-info"
                                        }
                                      ></i>
                                    </div>
                                  </div>

                                  <div className="overflow-hidden me-auto">
                                    <h5 className="font-size-13 text-truncate mb-1">
                                      {Moment(meetingObject.time).format(
                                        "MMMM Do dddd YYYY"
                                      )}
                                    </h5>
                                    <p className="text-muted text-truncate mb-0">
                                      {Moment(meetingObject.time).format(
                                        "h:mm a"
                                      )}
                                    </p>
                                  </div>

                                  {/* <div className="ml-2">
                                    <p className="text-muted">
                                      {Moment(
                                        resourceFile.parseObject.createdAt
                                      ).format("DD/MM/YYYY")}
                                    </p>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </Card>
                        ))}
                      {(props.meetingDetails == undefined ||
                        props.meetingDetails.length === 0) && (
                        <div className="text-center">
                          <p>No active live class</p>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </PerfectScrollbar>
            </TabPane>
          )}

          {/* <TabPane tabId="3"></TabPane> */}
        </TabContent>
      </div>
    </Container>
  );
};

export default CourseContent;
