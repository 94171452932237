import * as actions from "./actionTypes";

export const setLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const getLiveMeetingsForKid = (recipientIds, objectIds) => {
  return {
    type: actions.GET_LIVE_MEETINGS_FOR_KID,
    payload: { recipientIds, objectIds },
  };
};

export const updateLiveMeetingsForKid = (liveMeetingObjects) => {
  return {
    type: actions.UPDATE_LIVE_MEETINGS_FOR_KID,
    payload: { liveMeetingObjects },
  };
};
