import * as actions from "./actionTypes";

export const setLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const getOnlineLearningSessionForKidIds = (kidIds) => {
  return {
    type: actions.GET_ONLINE_LEARNING_SESSION_FOR_KID_IDS,
    payload: { kidIds },
  };
};

export const updateOnlineLearningSessionForKidIds = (
  onlineLearningSessionObjects
) => {
  return {
    type: actions.UPDATE_ONLINE_LEARNING_SESSION_FOR_KID_IDS,
    payload: { onlineLearningSessionObjects },
  };
};
