import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as GroupMapHelper from "../../helpers/ParseHelpers/groupMapHelper";
import * as actionTypes from "./actionTypes";
import * as GroupMapAction from "./actions";

function* getGroupMapsForUserIdSaga({ payload: { userId } }) {
  try {
    const response = yield call(GroupMapHelper.getGroupMapsForUserId, userId);
    yield put(GroupMapAction.setLoading(false));

    if (response) {
      yield put(GroupMapAction.updateGroupMapsForUserId(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetGroupMapsForUserIdSaga() {
  yield takeEvery(
    actionTypes.GET_GROUPMAPS_FOR_USERID,
    getGroupMapsForUserIdSaga
  );
}

function* teacherSaga() {
  yield all([fork(watchGetGroupMapsForUserIdSaga)]);
}
export default teacherSaga;
