import Parse from "parse";

const getFilesForFolderIds = (folderIds) => {
  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("Files");
      query.notEqualTo("isDeleted", true);
      query.containedIn("folderId", folderIds);
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export { getFilesForFolderIds };
