import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import Zoom from "./zoom/ConnectZoom";

import { connect } from "react-redux";

import { withTranslation } from "react-i18next";
import { matchPath } from "react-router";

const ConnectZoomPage = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/learningZoomConnect/:meetingId/:password/:kidName",
    exact: true,
    strict: false,
  });

  var meetingId = match && match.params.meetingId;
  var password = match && match.params.password;
  var kidName = match && match.params.kidName;

  useEffect(() => {
    let tempConfig = { ...config };
    tempConfig.meetingNumber = meetingId;
    tempConfig.passWord = password;
    tempConfig.userName = kidName;
    setConfig(tempConfig);
  }, [meetingId, password,kidName]);

  const API_KEYS = {
    apiKey: process.env.REACT_APP_ZOOM_API_KEY,
    apiSecret: process.env.REACT_APP_ZOOM_API_SECRET_KEY,
  };

  var zoomConfig = {
    apiKey: API_KEYS.apiKey, // can create from here https://marketplace.zoom.us/
    apiSecret: API_KEYS.apiSecret, // can create from here https://marketplace.zoom.us/
    meetingNumber: "",
    // meeting id generated when schedule a meeting in https://zoom.us/meeting/schedule
    // !!warning
    // meetingNumber must not have white space between numbers
    // 518 306 2219 - bad format
    // 5183062219 - good format
    leaveUrl: "https://zoom.us/",
    userName: "User", // (required)
    userEmail: "", // (optional) must be the attendee email address
    passWord: "",
    // !!warning, this is the passcode for meeting room, not for user password
    role: 0, // 0 for guest, 1 for host
  };
  const [config, setConfig] = useState(zoomConfig);

  const [isSubmitted, setIsSubmitted] = useState(true);

  return (
    <React.Fragment>
      <Zoom meetConfig={config} isSubmitted={isSubmitted}></Zoom>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  return {};
};

export default withTranslation()(
  withRouter(connect(mapStatetoProps, {})(ConnectZoomPage))
);
