import Parse from "parse";

const getCategoriesForCurriculumId = (curriculumId) => {
  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("LearningCategory");
      query.equalTo("curriculumId", curriculumId);
      query.limit(1000);
      query.find().then(
        (results) => {
          resolve(results);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export { getCategoriesForCurriculumId };
