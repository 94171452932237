import Parse from "parse";

const getParentForObjectId = (objectId) => {
  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("Parent");
      query.equalTo("objectId", objectId);
      query.find().then(
        (results) => {
          resolve(results);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export { getParentForObjectId };
