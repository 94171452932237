import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as Constants from "../../../Constants";
import {
  getParentForObjectId,
  getTeacherForObjectId,
} from "../../../store/actions";
import Parse from "parse";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// users
import userImage from "../../../assets/images/users/user.png";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("");
  const [userphoto, setuserphoto] = useState(undefined);

  useEffect(() => {
    if (props.username) {
      setusername(props.username);
    }
  }, [props.username]);
  useEffect(() => {
    if (props.userphoto) {
      setuserphoto(props.userphoto);
    }
  }, [props.userphoto]);

  // useEffect(() => {
  //   if (props.userObjectTeacher) {
  //     if (props.userObjectTeacher.length > 0) {
  //       let tempUserName = "";
  //       if (props.userObjectTeacher[0].attributes.Name) {
  //         tempUserName = props.userObjectTeacher[0].attributes.Name;
  //         setusername(tempUserName);
  //         localStorage.setItem(Constants.USER_PROFILE_NAME, tempUserName);
  //       }
  //       if (props.userObjectTeacher[0].attributes.Photo) {
  //         setuserphoto(props.userObjectTeacher[0].attributes.Photo._url);
  //         localStorage.setItem(
  //           Constants.USER_PROFILE_PHOTO,
  //           props.userObjectTeacher[0].attributes.Photo._url
  //         );
  //       } else {
  //         localStorage.removeItem(Constants.USER_PROFILE_PHOTO);
  //       }
  //     } else {
  //     }
  //   }
  // }, [props.userObjectTeacher]);

  // useEffect(() => {
  //   if (props.userObjectParent) {
  //     if (props.userObjectParent.length > 0) {
  //       let parent = props.userObjectParent[0];
  //       let tempUserName = "";
  //       if (parent.attributes.Name) {
  //         tempUserName = parent.attributes.Name;
  //         setusername(tempUserName);
  //         localStorage.setItem(Constants.USER_PROFILE_NAME, tempUserName);
  //       }
  //       if (parent.attributes.Photo) {
  //         setuserphoto(parent.attributes.Photo._url);
  //         localStorage.setItem(
  //           Constants.USER_PROFILE_PHOTO,
  //           parent.attributes.Photo._url
  //         );
  //       } else {
  //         localStorage.removeItem(Constants.USER_PROFILE_PHOTO);
  //       }
  //     } else {
  //       let currentUser = Parse.User.current();
  //       props.getTeacherForObjectId(currentUser.attributes.username);
  //     }
  //   }
  // }, [props.userObjectParent]);

  // useEffect(() => {
  //   // if (localStorage.getItem("authUser")) {
  //   //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //   //     const obj = JSON.parse(localStorage.getItem("authUser"));
  //   //     setusername(obj.displayName);
  //   //   } else if (
  //   //     process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //   //     process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //   //   ) {
  //   //     const obj = JSON.parse(localStorage.getItem("authUser"));
  //   //     setusername(obj.username);
  //   //   }
  //   // }
  //   let currentUser = Parse.User.current();
  //   if (currentUser) {
  //     props.getParentForObjectId(currentUser.attributes.username);

  //     var name = localStorage.getItem(Constants.USER_PROFILE_NAME);
  //     if (name && name.length > 0) {
  //       setusername(name);
  //     } else {
  //     }

  //     var photo = localStorage.getItem(Constants.USER_PROFILE_PHOTO);
  //     if (photo && photo.length > 0) {
  //       setuserphoto(photo);
  //     }
  //   }
  // }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={userphoto ?? userImage}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {props.t("Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1"/>
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1"/>
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1"/>
            {props.t("Lock screen")}
          </DropdownItem> */}
          {/* <div className="dropdown-divider" /> */}
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  const { userObjectParent } = state.parent;
  const { userObjectTeacher } = state.teacher;
  return { error, success, userObjectParent, userObjectTeacher };
};

export default withRouter(
  connect(mapStatetoProps, { getParentForObjectId, getTeacherForObjectId })(
    withTranslation()(ProfileMenu)
  )
);
