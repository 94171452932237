export const USER_PROFILE_NAME = "USER_PROFILE_NAME";
export const USER_PROFILE_EMAIL = "USER_PROFILE_EMAIL";
export const USER_PROFILE_PHOTO = "USER_PROFILE_PHOTO";
export const SCREEN_LOCK = "SCREEN_LOCK";

export const DISPLAY_TYPE_LIST = "DISPLAY_TYPE_LIST";
export const DISPLAY_TYPE_GRID = "DISPLAY_TYPE_GRID";

//Teacher
export const TEACHER_DISPLAY_TYPE = "TEACHER_DISPLAY_TYPE";

//UPDATED_TIME
export const REMINDER_UPDATED_TIME = "REMINDER_UPDATED_TIME";
export const TEACHER_UPDATED_TIME = "TEACHER_UPDATED_TIME";
export const CLASSROOM_UPDATED_TIME = "CLASSROOM_UPDATED_TIME";
export const ENQUIRY_UPDATED_TIME = "ENQUIRY_UPDATED_TIME";
export const SCHOOL_RATING_UPDATED_TIME = "SCHOOL_RATING_UPDATED_TIME";
export const PARENTS_UPDATED_TIME = "PARENTS_UPDATED_TIME";
export const KIDS_UPDATED_TIME = "KIDS_UPDATED_TIME";
export const ENQUIRYNOTE_UPDATED_TIME = "ENQUIRYNOTE_UPDATED_TIME";
export const ADMISSIONS_UPDATED_TIME = "ADMISSIONS_UPDATED_TIME";
export const ENQUIRY_QUESTIONS_UPDATED_TIME = "ENQUIRY_QUESTIONS_UPDATED_TIME";
export const GROUP_UPDATED_TIME = "GROUP_UPDATED_TIME";
export const ARCHIVE_GROUP_UPDATED_TIME = "ARCHIVE_GROUP_UPDATED_TIME";
export const KIDS_UPDATED_TIME_FOR_GROUP = "KIDS_UPDATED_TIME_FOR_GROUP";
export const SELECTED_SCHOOL_ID = "SELECTED_SCHOOL_ID";
export const SELECTED_KID_ID = "SELECTED_KID_ID";

export const PROGRAM_UPDATED_TIME = "PROGRAM_UPDATED_TIME";
export const ADMISSION_TITLE_UPDATED_TIME = "ADMISSION_TITLE_UPDATED_TIME";
export const ADMISSION_QUESTIONS_UPDATED_TIME =
  "ADMISSION_QUESTIONS_UPDATED_TIME";
export const MONEY_TRANSACTION_UPDATED_TIME = "MONEY_TRANSACTION_UPDATED_TIME";
export const CAMERA_UPDATED_TIME = "CAMERA_UPDATED_TIME";
export const FEE_TEMPLATE_UPDATED_TIME = "FEE_TEMPLATE_UPDATED_TIME";
export const KIDS_UPDATED_TIME_FOR_SCHOOL_ID =
  "KIDS_UPDATED_TIME_FOR_SCHOOL_ID";
export const LEARNING_CATEGORY_UPDATED_TIME_FOR_SCHOOL_ID =
  "LEARNING_CATEGORY_UPDATED_TIME_FOR_SCHOOL_ID";
export const LEARNING_CATEGORY_UPDATED_TIME_FOR_CURRICULUM_ID =
  "LEARNING_CATEGORY_UPDATED_TIME_FOR_CURRICULUM_ID";
export const LEARNING_ACTIVITY_UPDATED_TIME_FOR_CURRICULUM_ID =
  "LEARNING_ACTIVITY_UPDATED_TIME_FOR_CURRICULUM_ID";
export const LEARNING_FILTER_UPDATED_TIME_FOR_CURRICULUM_ID =
  "LEARNING_FILTER_UPDATED_TIME_FOR_CURRICULUM_ID";
export const CURRICULUM_UPDATED_TIME_FOR_OWNER_ID =
  "CURRICULUM_UPDATED_TIME_FOR_OWNER_ID";
export const ACADEMIC_CYCLE_UPDATED_TIME = "ACADEMIC_CYCLE_UPDATED_TIME";
export const LEARNING_SUBCATEGORY_UPDATED_TIME_FOR_CATEGORY_ID =
  "LEARNING_SUBCATEGORY_UPDATED_TIME_FOR_CATEGORY_ID";
export const DEFAULT_ADMISSION_QUESTION_UPDATED_TIME =
  "DEFAULT_ADMISSION_QUESTION_UPDATED_TIME";

export const DELETED_ENQUIRY_UPDATED_TIME = "DELETED_ENQUIRY_UPDATED_TIME";

//School Features Index
export const FEATURE_ACTIVITY_TRACKING_INDEX = 0;
export const FEATURE_ATTENDANCE_INDEX = 1;
export const FEATURE_DIARY_INDEX = 2;
export const FEATURE_PAYMENTS_INDEX = 3;
export const FEATURE_PICKNDROP_INDEX = 4;
export const FEATURE_MEDICAL_INDEX = 5;
export const FEATURE_TRANSPORT_INDEX = 6;
export const FEATURE_CUSTOM_DIARY_INDEX = 7;
export const FEATURE_PARENT_TRANSPORT_INDEX = 8;
export const FEATURE_NO_ATTENDANCE_MESSAGE_INDEX = 9;
export const FEATURE_ACTIVITY_TRACKING_PARENT_INDEX = 10;
export const FEATURE_OBSERVATION_REPORT_INDEX = 11;
export const FEATURE_WEB_MESSAGE_INDEX = 12;
export const FEATURE_EXPENSE_INDEX = 13;
export const FEATURE_CCTV_INDEX = 14;
export const FEATURE_VISITOR_INDEX = 15;
export const FEATURE_SMS_ON_ALERT_INDEX = 16;
export const FEATURE_PROGRAM_INDEX = 17;
export const FEATURE_MARKETPLACE_INDEX = 18;
export const FEATURE_E_LEARNING = 19;
export const FEATURE_GO_LIVE = 20;

//Features Index Values
export const FEATURE_ATTENDANCE_INDEX_VALUE_BASIC = 1;
export const FEATURE_ATTENDANCE_INDEX_VALUE_QR_SCAN = 2;
export const FEATURE_ATTENDANCE_INDEX_VALUE_NFC = 3;
export const FEATURE_ATTENDANCE_INDEX_VALUE_SELF = 4;

export const FEATURE_PARENT_TRANSPORT_INDEX_COMPANY = 1;
export const FEATURE_PARENT_TRANSPORT_INDEX_NO_QUERY = 2; //no messaging button for parent

//payment gateway options
export const GATEWAY_CC_AVENUE_INDEX = 0;
export const GATEWAY_PAY_U_INDEX = 1;
export const GATEWAY_AIR_PAY_INDEX = 2;

export const GATEWAY_PAY_U_PRODUCTION = 1;
export const GATEWAY_PAY_U_DEVELOPMENT = 2;

//SCHOOL

export const APP_SUBSCRIPTION_MODE_PARENT = 2;
export const APP_SUBSCRIPTION_MODE_SCHOOL = 1;

//TEACHER PERMISSIONS
export const TEACHER_CHILD_DEVELOPMENT_INDEX = 0;
export const TEACHER_ATTENDANCE_INDEX = 1;
export const TEACHER_MESSAGING_INDEX = 2;
export const TEACHER_PAYMENTS_INDEX = 3;
export const TEACHER_PICK_DROP_INDEX = 4;
export const TEACHER_MEDICAL_DETAILS_INDEX = 5;
export const TEACHER_TRANSPORT_INDEX = 6;
export const TEACHER_DAYCARE_INDEX = 7;
export const TEACHER_CHILD_DETAILS_INDEX = 8;
export const TEACHER_EXPENSE_INDEX = 9;
export const TEACHER_CCTV_INDEX = 10;
export const TEACHER_PAYMENT_ADMINISTRATOR = 11;
export const TEACHER_GROUPS = 12;
export const TEACHER_ENQUIRY = 13;

//ADDITIONAL SETTINGS FOR SCHOOL

export const ADDITIONAL_SETTING_CUSTOM_HELP_SCREEN = 0; //app
export const ADDITIONAL_SETTING_DAYCARE_FULL_HOUR_CHARGES = 1;
export const ADDITIONAL_SETTING_CHILD_DEV_MSG_DISABLED = 2; //server
export const ADDITIONAL_SETTING_PAYMENT_MSG_DISABLED = 3; //server
export const ADDITIONAL_SETTING_VOICE_MESSAGE_DISABLED_FOR_START_STOP = 4;
export const ADDITIONAL_SETTING_PENALTY = 5;
export const ADDITIONAL_SETTING_DIARY_APPROVAL = 6;
export const ADDITIONAL_SETTING_MARKETPLACE_VOICE = 7;
export const ADDITIONAL_SETTING_lilTriangle_CONTENT_SHARE_PERMISSIM = 8;
export const ADDITIONAL_SETTING_lilTriangle_EXTRA_PROTECTION = 9; //zoom call water mark and disbling emulator

//PARENT PERMISSIONS

export const PARENT_PERMISSIONS_EDIT_PARENT_DETAILS_INDEX = 0;
export const PARENT_PERMISSIONS_EDIT_MEDICAL_DETAILS_INDEX = 1;
export const PARENT_PERMISSIONS_EDIT_PICKUP_DETAILS_INDEX = 2;
export const PARENT_PERMISSIONS_FAVORITE_COLOR_INDEX = 3;
export const PARENT_PERMISSIONS_REFER_CHILD_INDEX = 4;
export const PARENT_PERMISSIONS_SHARE_IMAGE_INDEX = 5;
export const PARENT_PERMISSIONS_HELP_VIDEO_INDEX = 6;
export const PARENT_PERMISSIONS_EDIT_PROFILE_PICK_INDEX = 7;
export const PARENT_PERMISSIONS_PARENT_FEEDBACK_INDEX = 8;
export const PARENT_PERMISSIONS_PARENT_MARKETPLACE_INDEX = 9;

// Enquiry Question types
export const QESTION_TEXT_SINGLE_LINE = 1;
export const QESTION_TEXT_MULTI_LINE = 2;
export const QESTION_NUMBER = 3;
export const QESTION_YES_NO_NO_COMMENTS = 4;
export const QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST = 5;
export const QESTION_CHOICE_MULTI_SELECTION_FROM_LIST = 6;
export const QESTION_DATE = 7;
export const QESTION_RANGE_TIME = 8;
export const QESTION_GEO_POINT = 9;
export const QESTION_YES_NO_COMMENT_ON_YES = 10;
export const QESTION_YES_NO_COMMENT_ON_NO = 11;
export const QESTION_YES_NO_WITH_COMMENT = 12;
export const QUESTION_EMAIL = 13;
export const QUESTION_PHONE_NUMBER = 14;
export const QESTION_CHECKBOX = 15;
export const QESTION_UPLOAD_IMAGE = 16;
export const QESTION_UPLOAD_DOCUMENT = 17;

//Exam Question types
export const EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST = 1;
export const EXAM_FILL_IN_THE_BLANKS = 2;
export const EXAM_ARRANGE_THE_FOLLOWING = 3;
export const EXAM_MATCH_THE_FOLLOWING = 4;
export const EXAM_CHOICE_MULTI_SELECTION_FROM_LIST = 5;
export const EXAM_LONG_ANSWER = 6;

//School Level
export const FRANCHISE_LEVEL = 1;
export const INSTITUTE_LEVEL = 2;
export const SCHOOL_LEVEL = 3;

//Franchise Features Index
export const FRANCHISE_REPORTS_INDEX = 0;
export const FRANCHISE_ADMISSION_FORM_INDEX = 1;
