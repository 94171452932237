import * as actions from "./actionTypes";

const initialState = {
  quizObjects: undefined,
  isLoadingQuiz: true,
};

const Quiz = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING_QUIZ:
      state = { ...state, isLoadingQuiz: action.payload.boolValue };
      break;

    case actions.UPDATE_QUIZ_FOR_ACTIVITY_IDS:
      state = {
        ...state,
        quizObjects: action.payload.quizObjects,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Quiz;
