import * as actions from "./actionTypes";

export const setLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const getMediaCategoriesForTargetAudienceIds = (targetAudienceIds) => {
  return {
    type: actions.GET_MEDIA_CATEGORIES_FOR_TARGET_AUDIENCE_IDS,
    payload: { targetAudienceIds },
  };
};

export const updateMediaCategories = (mediaCategories) => {
  return {
    type: actions.UPDATE_MEDIA_CATEGORIES,
    payload: { mediaCategories },
  };
};
