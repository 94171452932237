import * as actions from "./actionTypes";

export const setLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const getLearningActivitiesForIds = (activityIds) => {
  return {
    type: actions.GET_LEARNING_ACTIVITIES_FOR_IDS,
    payload: { activityIds },
  };
};

export const getLearningActivitiesForCategoryIds = (categoryIds) => {
  return {
    type: actions.GET_LEARNING_ACTIVITIES_FOR_CATEGORY_IDS,
    payload: { categoryIds },
  };
};

export const updateLearningActivitiesForIds = (learningActivityObjects) => {
  return {
    type: actions.UPDATE_LEARNING_ACTIVITIES_FOR_IDS,
    payload: { learningActivityObjects },
  };
};
