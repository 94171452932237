import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//E-commerce
import ecommerce from "./e-commerce/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//invoices
import invoices from "./invoices/reducer";

//projects
import projects from "./projects/reducer";

//mails
import mails from "./mails/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//onlineLearningSession
import onlineLearningSession from "./onlineLearningSession/reducer";

//onlineLearningTracking
import onlineLearningTracking from "./onlineLearningTracking/reducer";

//learningActivity
import learningActivity from "./learningActivity/reducer";

//webcode
import webcode from "./webcode/reducer";

//parent
import parent from "./parent/reducer";

//kid
import kid from "./kid/reducer";

//teacher
import teacher from "./teacher/reducer";

//file
import files from "./files/reducer";

//school
import school from "./school/reducer";

//mediaCategories
import mediaCategories from "./mediaCategories/reducer";

//learningCategory
import learningCategory from "./learningCategory/reducer";

//quiz
import quiz from "./quiz/reducer";

//groupMap
import groupMap from "./groupMap/reducer";

//reminder
import reminder from "./reminder/reducer";

//liveMeeting
import liveMeeting from "./liveMeeting/reducer";

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  mails,
  chat,
  crypto,
  invoices,
  projects,
  tasks,
  contacts,
  onlineLearningSession,
  learningActivity,
  webcode,
  parent,
  kid,
  teacher,
  files,
  school,
  mediaCategories,
  learningCategory,
  onlineLearningTracking,
  quiz,
  reminder,
  groupMap,
  liveMeeting,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    const { routing } = state;
    state = { routing };
  }

  return appReducer(state, action);
};

export default rootReducer;
