import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as LearningCategoryHelper from "../../helpers/ParseHelpers/learningCategoryHelper";
import * as actionTypes from "./actionTypes";
import * as LearningCategoryAction from "./actions";

function* getCategoriesForCurriculumIdSaga({ payload: { curriculumId } }) {
  try {
    const response = yield call(
      LearningCategoryHelper.getCategoriesForCurriculumId,
      curriculumId
    );
    yield put(LearningCategoryAction.setLoading(false));

    if (response) {
      yield put(LearningCategoryAction.updateCategories(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetCategoriesForCurriculumIdSaga() {
  yield takeEvery(
    actionTypes.GET_LEARNING_CATEGORY_FOR_CURRICULUM_ID,
    getCategoriesForCurriculumIdSaga
  );
}

function* learningCategorySaga() {
  yield all([fork(watchGetCategoriesForCurriculumIdSaga)]);
}
export default learningCategorySaga;
