import * as actions from "./actionTypes";

const initialState = {
  userObjectTeacher: undefined,
  isLoadingTeacher: true,
};

const Teacher = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING:
      state = { ...state, isLoadingTeacher: action.payload.boolValue };
      break;

    case actions.UPDATE_USER_OBJECT_TEACHER:
      state = {
        ...state,
        userObjectTeacher: action.payload.userObjectTeacher,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Teacher;
