import * as actions from "./actionTypes";

export const setLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const getOnlineLearningTrackingsForKidId = (kidId) => {
  return {
    type: actions.GET_ONLINE_LEARNING_TRACKINGS_FOR_KID_ID,
    payload: { kidId },
  };
};

export const updateOnlineLearningTrackingsForKidId = (
  onlineLearningTrackingObjects
) => {
  return {
    type: actions.UPDATE_ONLINE_LEARNING_TRACKINGS_FOR_KID_ID,
    payload: { onlineLearningTrackingObjects },
  };
};
