import * as actions from "./actionTypes";

export const setLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const getGroupMapsForUserId = (userId) => {
  return {
    type: actions.GET_GROUPMAPS_FOR_USERID,
    payload: { userId },
  };
};

export const updateGroupMapsForUserId = (groupMapObjects) => {
  return {
    type: actions.UPDATE_GROUPMAPS_FOR_USERID,
    payload: { groupMapObjects },
  };
};
