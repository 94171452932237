import Parse from "parse";

const getKidsForFamilyId = (familyId) => {
  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("Kid");
      query.equalTo("FamilyID", familyId);
      query.exists("ClassRoomID")
      query.exists("SchoolID")
      query.notEqualTo("Deleted", true);
      query.find().then(
        (results) => {
          resolve(results);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};
const getProfileKidForObject = (objectId) => {
  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("Kid");
      query.equalTo("objectId", objectId);
      query.notEqualTo("deleted", true);
      query.find().then(
        (results) => {
          resolve(results);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

const getKidForObjectId = (objectId) => {
  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("Kid");
      query.equalTo("objectId", objectId);
      query.notEqualTo("deleted", true);
      query.find().then(
        (results) => {
          resolve(results);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};
export { getKidsForFamilyId, getKidForObjectId,getProfileKidForObject };
