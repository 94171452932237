import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as LearningActivityHelper from "../../helpers/ParseHelpers/learningActivityHelper";
import * as actionTypes from "./actionTypes";
import * as LearningActivityAction from "./actions";

function* getLearningActivitiesForIdsSaga({ payload: { activityIds } }) {
  try {
    const response = yield call(
      LearningActivityHelper.getLearningActivitiesForIds,
      activityIds
    );
    yield put(LearningActivityAction.setLoading(false));

    if (response) {
      yield put(
        LearningActivityAction.updateLearningActivitiesForIds(response)
      );
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getLearningActivitiesForCategoryIdsSaga({
  payload: { categoryIds },
}) {
  try {
    const response = yield call(
      LearningActivityHelper.getLearningActivitiesForCategoryIds,
      categoryIds
    );
    yield put(LearningActivityAction.setLoading(false));

    if (response) {
      yield put(
        LearningActivityAction.updateLearningActivitiesForIds(response)
      );
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetLearningActivitiesForIdsSaga() {
  yield takeEvery(
    actionTypes.GET_LEARNING_ACTIVITIES_FOR_IDS,
    getLearningActivitiesForIdsSaga
  );
}

export function* watchGetLearningActivitiesForCategoryIdsSaga() {
  yield takeEvery(
    actionTypes.GET_LEARNING_ACTIVITIES_FOR_CATEGORY_IDS,
    getLearningActivitiesForCategoryIdsSaga
  );
}

function* learningActivitySaga() {
  yield all([
    fork(watchGetLearningActivitiesForIdsSaga),
    fork(watchGetLearningActivitiesForCategoryIdsSaga),
  ]);
}
export default learningActivitySaga;
