import Parse from "parse";

const getQuizForActivityIds = (activityIds) => {
  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("Quiz");
      query.containedIn("activityId", activityIds);
      query.find().then(
        (results) => {
          resolve(results);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export { getQuizForActivityIds };
