import * as actions from "./actionTypes";

export const setLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const getKidsForFamilyId = (familyId) => {
  return {
    type: actions.GET_KIDS_FOR_FAMILY_ID,
    payload: { familyId },
  };
};

export const getProfileKidForObjectId = (objectId) => {
  return {
    type: actions.GET_PROFILE_KID_FOR_OBJECT_ID,
    payload: { objectId },
  };
}

export const getKidForObjectId = (objectId) => {
  return {
    type: actions.GET_KID_FOR_OBJECT_ID,
    payload: { objectId },
  };
};



export const updateProfileKids = (kids) => {
  return {
    type: actions.UPDATE_PROFILE_KIDS,
    payload: { kids },
  };
};

export const updateKid = (kidList) => {
  return {
    type: actions.UPDATE_KID,
    payload: { kidList },
  };
};
