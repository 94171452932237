import Parse from "parse";

const getOnlineLearningTrackingsForKidId = (kidId) => {
  try {
    return new Promise((resolve, reject) => {
      var OnlineLearningTracking = Parse.Object.extend(
        "OnlineLearningTracking"
      );
      var query = new Parse.Query(OnlineLearningTracking);
      query.equalTo("kidId", kidId);
      query.notEqualTo("isDeleted", true);
      query.descending("createdAt");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export { getOnlineLearningTrackingsForKidId };
