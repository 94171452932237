import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";

import { Row, Col } from "reactstrap";

class SkeletonComponent extends Component {
  state = {};

  getCount(count) {
    var list = [];
    for (var i = 0; i < count; i++) {
      list.push(i);
    }

    return list;
  }

  coursePageLoading() {
    return (
      <Row className="p-3 ">
        <Col lg="9">
          <Skeleton className="p-1" height={500} />

          <h4>
            {" "}
            <Skeleton width={400} height={30} />{" "}
          </h4>
          <Skeleton width={600} height={15} />
        </Col>
        <Col lg="3">
          <Skeleton className="mb-2" height={60} count={10} />
        </Col>
      </Row>
    );
  }

  profileSkeleton() {
    return (
      <div className="border rounded ">
        <Row>
          <Col className="m-4">
            {" "}
            <Skeleton width={200} height={30} />
          </Col>
          <Col className="m-4">
            {" "}
            <Skeleton width={80} height={40} className="float-right" />{" "}
          </Col>
        </Row>
        <Skeleton className="m-2" height={1} />
        <Row className="m-2">
          <Col lg={2}>
            <Skeleton circle={true} height={100} width={100} />
          </Col>
          <Col lg={10}>
            {" "}
            <Skeleton height={500} />{" "}
          </Col>
        </Row>
      </div>
    );
  }

  chilListSkeleton(count, type) {
    var list = this.getCount(count);
    return (
      <div>
        {type === "child-list-simple" ? null : (
          <Skeleton height={50} className="p-3" />
        )}

        {list.map((i) => (
          <Row key={i} className="ml-3 mt-2">
            <Skeleton circle={true} height={50} width={50} />
            <Col>
              <h4>
                {" "}
                <Skeleton width={100} height={30} />{" "}
              </h4>
              <Skeleton width={300} height={15} />
            </Col>
          </Row>
        ))}
      </div>
    );
  }
  simpleSkeleton(count) {
    return <Skeleton height={80} count={count} />;
  }

  simpleSkeletonThin(count) {
    return <Skeleton height={50} count={count} />;
  }

  defaultSkeleton() {
    return <Skeleton height={80} count={8} />;
  }
  render() {
    let skeletonLayout = this.defaultSkeleton();

    if (
      this.props.SkeletonContent != null ||
      this.props.SkeletonContent != undefined
    ) {
      var type = this.props.SkeletonContent.type;
      var count = this.props.SkeletonContent.cellCount;
      if (type === "child-list") {
        skeletonLayout = this.chilListSkeleton(count, type);
      } else if (type === "child-list-simple") {
        skeletonLayout = this.chilListSkeleton(count, type);
      } else if (type === "child-list") {
        skeletonLayout = this.simpleSkeleton(count);
      } else if (type === "simple-thin") {
        skeletonLayout = this.simpleSkeletonThin(count);
      } else if (type === "course-progress") {
        skeletonLayout = this.coursePageLoading();
      } else if (type === "profile") {
        skeletonLayout = this.profileSkeleton();
      }
    }

    return skeletonLayout;
  }
}

export default SkeletonComponent;
