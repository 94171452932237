import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as OnlineLearningSessionHelper from "../../helpers/ParseHelpers/onlineLearningSesionHelper";
import * as actionTypes from "./actionTypes";
import * as OnlineLearningSessionActions from "./actions";

function* getOnlineLearningSessionForKidIdsSaga({ payload: { kidIds } }) {
  try {
    const response = yield call(
      OnlineLearningSessionHelper.getOnlineLearningSessionForKidIds,
      kidIds
    );
    yield put(OnlineLearningSessionActions.setLoading(false));

    if (response) {
      yield put(
        OnlineLearningSessionActions.updateOnlineLearningSessionForKidIds(
          response
        )
      );
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetOnlineLearningSessionForKidIdsSaga() {
  yield takeEvery(
    actionTypes.GET_ONLINE_LEARNING_SESSION_FOR_KID_IDS,
    getOnlineLearningSessionForKidIdsSaga
  );
}

function* onlineLearningSessionSaga() {
  yield all([fork(watchGetOnlineLearningSessionForKidIdsSaga)]);
}
export default onlineLearningSessionSaga;
