import * as actions from "./actionTypes";

const initialState = {
  onlineLearningTrackingObjects: undefined,
  isLoadingOLT: true,
};

const OnlineLearningTracking = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING:
      state = { ...state, isLoadingOLT: action.payload.boolValue };
      break;

    case actions.UPDATE_ONLINE_LEARNING_TRACKINGS_FOR_KID_ID:
      state = {
        ...state,
        onlineLearningTrackingObjects:
          action.payload.onlineLearningTrackingObjects,
      };

      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default OnlineLearningTracking;
