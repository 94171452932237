import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactDrawer from "react-drawer";

import { connect } from "react-redux";

import { Link, withRouter } from "react-router-dom";
import * as Constants from "../../Constants";
// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  getParentForObjectId,
  getTeacherForObjectId,
  getKidsForFamilyId,
  getProfileKidForObjectId,
} from "../../store/actions";

import userImg from "../../assets/images/users/user.png";
import Parse from "parse";

// reactstrap
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Label,
} from "reactstrap";
import userImage from "../../assets/images/users/user.png";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import RightSidebar from "../CommonForBoth/RightSidebar";

import megamenuImg from "../../assets/images/megamenu-img.png";
import logo from "../../assets/images/logo.svg";
import logoLight from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import lilLogo from "../../assets/images/lilLogo.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

//i18n
import { withTranslation } from "react-i18next";
import profile from "store/auth/profile/reducer";
import { matchPath } from "react-router";

const Header = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/learningActivityView/:schoolId/:userId",
    exact: true,
    strict: false,
  });

  const selectedKidId = match && match.params.userId;
  const [menu, setMenu] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);

  const [username, setusername] = useState(undefined);
  const [userphoto, setuserphoto] = useState(undefined);

  const [kidName, setKidName] = useState(undefined);
  const [kidPhoto, setKidPhoto] = useState(undefined);

  const [profileList, setProfileList] = useState([]);

  const toggleTopDrawer = () => {
    setPosition("right");
    setOpen(!open);
  };

  useEffect(() => {
    if (props.profileKids && selectedKidId) {
      let kids = props.profileKids;
      let tempProfileList = [];
      for (var i in kids) {
        var temp = {};
        temp.id = kids[i].id;
        temp.name = kids[i].attributes.Name;
        temp.parseObject = kids[i];
        if (kids[i].attributes.Photo) {
          temp.photoURL = kids[i].attributes.Photo._url;
        } else {
          if (kids[i].attributes.Gender == "Male") {
            //temp.photoURL = "images/he.png";
          } else {
            //temp.photoURL = "images/she.png";
          }
        }
        tempProfileList.push(temp);
         if (props.userObjectParent && props.userObjectParent.length === 0) {
          let tempUserName = kids[i].attributes.Name
          setusername(tempUserName);
          localStorage.setItem(Constants.USER_PROFILE_NAME, tempUserName);
          let photo = kids[i].attributes.Photo && kids[i].attributes.Photo._url;
          if (photo) {
            setuserphoto(photo);
            localStorage.setItem(
              Constants.USER_PROFILE_PHOTO,
              photo
            );
          }
        } else if (kids[i].id === selectedKidId) {
          let name = kids[i].attributes.Name;
          let photo = kids[i].attributes.Photo && kids[i].attributes.Photo._url;

          setKidName(name);
          if (photo) {
            setKidPhoto(photo);
          }
          console.log(name);
        } 
      } 
      setProfileList(tempProfileList);
      
    }
  }, [props.profileKids, selectedKidId]);

  useEffect(() => {
    if (props.userObjectTeacher) {
      if (props.userObjectTeacher.length > 0) {
        let tempUserName = "";
        if (props.userObjectTeacher[0].attributes.Name) {
          tempUserName = props.userObjectTeacher[0].attributes.Name;
          setusername(tempUserName);
          localStorage.setItem(Constants.USER_PROFILE_NAME, tempUserName);
        }
        if (props.userObjectTeacher[0].attributes.Photo) {
          setuserphoto(props.userObjectTeacher[0].attributes.Photo._url);
          localStorage.setItem(
            Constants.USER_PROFILE_PHOTO,
            props.userObjectTeacher[0].attributes.Photo._url
          );
        } else {
          localStorage.removeItem(Constants.USER_PROFILE_PHOTO);
        }
      } else {
        let currentUser = Parse.User.current();
        if (currentUser) {
          props.getProfileKidForObjectId(currentUser.attributes.username);
        }
      }
    }
  }, [props.userObjectTeacher]);

  useEffect(() => {
    if (props.userObjectParent) {
      if (props.userObjectParent.length > 0) {
        let parent = props.userObjectParent[0];
        props.getKidsForFamilyId(parent.attributes.FamilyID);
        let tempUserName = "";
        if (parent.attributes.Name) {
          tempUserName = parent.attributes.Name;
          setusername(tempUserName);
          localStorage.setItem(Constants.USER_PROFILE_NAME, tempUserName);
        }
        if (parent.attributes.Photo) {
          setuserphoto(parent.attributes.Photo._url);
          localStorage.setItem(
            Constants.USER_PROFILE_PHOTO,
            parent.attributes.Photo._url
          );
        } else {
          localStorage.removeItem(Constants.USER_PROFILE_PHOTO);
        }
      } else {
        let currentUser = Parse.User.current();
        if (currentUser) {
          props.getTeacherForObjectId(currentUser.attributes.username);
        }
      }
    }
  }, [props.userObjectParent]);

  useEffect(() => {
    // if (localStorage.getItem("authUser")) {
    //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //     const obj = JSON.parse(localStorage.getItem("authUser"));
    //     setusername(obj.displayName);
    //   } else if (
    //     process.env.REACT_APP_DEFAULTAUTH === "fake" ||
    //     process.env.REACT_APP_DEFAULTAUTH === "jwt"
    //   ) {
    //     const obj = JSON.parse(localStorage.getItem("authUser"));
    //     setusername(obj.username);
    //   }
    // }

    if (Parse.User.current()) {
      let currentUser = Parse.User.current();
      props.getParentForObjectId(currentUser.attributes.username);

      var name = localStorage.getItem(Constants.USER_PROFILE_NAME);
      if (name && name.length > 0) {
        setusername(name);
      } else {
      }

      var photo = localStorage.getItem(Constants.USER_PROFILE_PHOTO);
      if (photo && photo.length > 0) {
        setuserphoto(photo);
      }
    }
  }, [Parse.User.current()]);

  const onDrawerClose = () => {
    setOpen(false);
  };

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const getUIWithoutDropdown = () => {
    return (<Dropdown  isOpen={false} className="d-none d-lg-inline-block ms-1">
      <DropdownToggle style={{cursor:"default"}} className="btn header-item " caret tag="button"><img
      className="rounded-circle header-profile-user"
      src={kidPhoto ?? userImage}
      alt="Header Avatar"

    />
    <span className="d-none d-xl-inline-block ms-2 me-1">
      {kidName ?? ""}
    </span>
    </DropdownToggle></Dropdown>)
  }

  const getChooseProfileDropdown = (
    classNameForDropdown = "d-none d-lg-inline-block ms-1"
  ) => {
    return (
      <Dropdown
        className={classNameForDropdown}
        isOpen={menu}
        toggle={() => setMenu(!menu)}
      >
        <DropdownToggle className="btn header-item " caret tag="button">
          <img
            className="rounded-circle header-profile-user"
            src={kidPhoto ?? userImage}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {kidName ?? ""}
          </span>
          <i className="mdi mdi-chevron-down" />

          {/* {props.t("Choose Profile")} <i className="mdi mdi-chevron-down" /> */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg right">
          {/* <Row>
                  <Col sm={8}>
                    <Row>
                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("UI Components")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{props.t("Lightbox")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Range Slider")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Sweet Alert")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Rating")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Forms")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Tables")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Charts")}</Link>
                          </li>
                        </ul>
                      </Col>

                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("Applications")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{props.t("Ecommerce")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Calendar")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Email")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Projects")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Tasks")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Contacts")}</Link>
                          </li>
                        </ul>
                      </Col>

                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("Extra Pages")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{props.t("Light Sidebar")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Compact Sidebar")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Horizontal layout")}</Link>
                          </li>
                          <li>
                            <Link to="#"> {props.t("Maintenance")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Coming Soon")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Timeline")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("FAQs")}</Link>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={4}>
                    <Row>
                      <Col sm={6}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("UI Components")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{props.t("Lightbox")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Range Slider")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Sweet Alert")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Rating")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Forms")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Tables")}</Link>
                          </li>
                          <li>
                            <Link to="#">{props.t("Charts")}</Link>
                          </li>
                        </ul>
                      </Col>

                      <Col sm={5}>
                        <div>
                          <img
                            src={megamenuImg}
                            alt=""
                            className="img-fluid mx-auto d-block"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Row>
                {profileList &&
                  profileList.length > 0 &&
                  profileList.map((profile, key) => (
                    <div
                      style={{
                        width: "150px",
                        height: "150px",
                        backgroundColor: "rgb(255,255,255)",
                      }}
                      key={key}
                      className="mr-3"
                    >
                      <Link
                        to={
                          "/learningActivityView/" +
                          profile.parseObject.attributes.SchoolID +
                          "/" +
                          profile.id
                        }
                        className="mt-1"
                        style={{ fontSize: "16px" }}
                        onClick={() => {
                          localStorage.setItem(
                            Constants.SELECTED_KID_ID,
                            profile.id
                          );
                          console.log("kidId" + profile.id);
                        }}
                      >
                        <img
                          className="mt-4 rounded-circle"
                          src={profile.photoURL ?? userImg}
                          style={{
                            width: "100px",
                            height: "100px",
                            textAlign: "center",
                          }}
                        ></img>

                        <br />

                        {profile.name}
                      </Link>
                    </div>
                  ))}
              </Row>
            </center>
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Label className="logo logo-dark">
                <span className="logo-sm">
                  <img src={lilLogo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={lilLogo} alt="" height="17" />
                </span>
              </Label>

              <Label className="logo logo-light">
                <span className="logo-sm">
                  <img src={lilLogo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={lilLogo} alt="" height="19" />
                </span>
              </Label>
            </div>

            {/* <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button> */}

            {props.userObjectParent &&
              props.profileKids && props.profileKids.length > 1 &&
              getChooseProfileDropdown("d-lg-none header-item ms-1")}
              

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <span className="bx bx-search-alt" />
              </div>
            </form> */}

            {props.userObjectParent &&
              props.profileKids && props.profileKids.length > 1 &&
              getChooseProfileDropdown()}
              {props.userObjectParent && props.userObjectParent.length > 0 &&
              props.profileKids && props.profileKids.length === 1 &&
              getUIWithoutDropdown()}
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              {/* <button
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
                onClick={() => setSearch(!isSearch)}
              >
                <i className="mdi mdi-magnify" />
              </button> */}
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.t("Search") + "..."}
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* <LanguageDropdown /> */}

            {/* <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp)
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon "
                caret
                tag="button"
              >
                <i className="bx bx-customize" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg" right>
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown> */}

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                className="btn header-item noti-icon "
                onClick={() => {
                  toggleFullscreen();
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            {/* <NotificationDropdown /> */}

            <ProfileMenu username={username} userphoto={userphoto} />

            {/* <div className="dropdown d-inline-block">
              <button
                onClick={toggleTopDrawer} disabled={open}
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div> */}
          </div>
        </div>
      </header>
      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  const { userObjectParent } = state.parent;
  const { userObjectTeacher } = state.teacher;
  const { profileKids } = state.kid;
  return {
    layoutType,
    showRightSidebar,
    leftMenu,
    userObjectTeacher,
    userObjectParent,
    profileKids,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    getParentForObjectId,
    getTeacherForObjectId,
    getKidsForFamilyId,
    getProfileKidForObjectId,
  })(withTranslation()(Header))
);
