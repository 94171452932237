import Parse from "parse";

const getOnlineLearningSessionForKidIds = (kidIds) => {
  try {
    return new Promise((resolve, reject) => {
      var OnlineLearningSession = Parse.Object.extend("OnlineLearningSession");
      var query = new Parse.Query(OnlineLearningSession);
      query.containedIn("recipients", kidIds);
      query.notEqualTo("isDeleted", true);
      query.descending("createdAt");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export { getOnlineLearningSessionForKidIds };
