import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as MediaCategoriesHelper from "../../helpers/ParseHelpers/mediaCategoriesHelper";
import * as actionTypes from "./actionTypes";
import * as MediaCategoriesAction from "./actions";

function* getMediaCategoriesForTargetAudienceIdsSage({
  payload: { targetAudienceIds },
}) {
  try {
    const response = yield call(
      MediaCategoriesHelper.getMediaCategoriesForTargetAudienceIds,
      targetAudienceIds
    );
    yield put(MediaCategoriesAction.setLoading(false));

    if (response) {
      yield put(MediaCategoriesAction.updateMediaCategories(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetMediaCategoriesForTargetAudienceIdsSage() {
  yield takeEvery(
    actionTypes.GET_MEDIA_CATEGORIES_FOR_TARGET_AUDIENCE_IDS,
    getMediaCategoriesForTargetAudienceIdsSage
  );
}

function* mediaCategoriesSaga() {
  yield all([fork(watchGetMediaCategoriesForTargetAudienceIdsSage)]);
}
export default mediaCategoriesSaga;
