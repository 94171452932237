import * as actions from "./actionTypes";

export const setLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const saveUUIDToWebCode = (uuid) => {
  return {
    type: actions.SAVE_UUID_TO_WEBCODE,
    payload: { uuid },
  };
};

export const updateWebcodeObject = (afterSaveWebcodeObject) => {
  return {
    type: actions.UPDATE_WEBCODE_OBJECT,
    payload: { afterSaveWebcodeObject },
  };
};
