export const GET_LEARNING_ACTIVITIES_FOR_IDS =
  "GET_LEARNING_ACTIVITIES_FOR_IDS";

export const GET_LEARNING_ACTIVITIES_FOR_CATEGORY_IDS =
  "GET_LEARNING_ACTIVITIES_FOR_CATEGORY_IDS";

export const UPDATE_LEARNING_ACTIVITIES_FOR_IDS =
  "UPDATE_LEARNING_ACTIVITIES_FOR_IDS";

export const SET_LOADING = "SET_LOADING";
