import Parse from "parse";

const getMediaCategoriesForTargetAudienceIds = (targetAudienceIds) => {
  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("MediaCategories");
      query.containedIn("targetAudienceId", targetAudienceIds);
      query.equalTo("targetType", "COURSE");
      query.limit(1000);
      query.ascending("order");
      query.find().then(
        (results) => {
          resolve(results);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export { getMediaCategoriesForTargetAudienceIds };
