import * as actions from "./actionTypes";

const initialState = {
  reminderObjects: undefined,
  isLoadingReminder: true,
};

const Reminder = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING:
      state = { ...state, isLoadingReminder: action.payload.boolValue };
      break;

    case actions.UPDATE_REMINDERS_FOR_LIVE_MEETING:
      state = {
        ...state,
        reminderObjects: action.payload.reminderObjects,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Reminder;
