import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as ParentHelper from "../../helpers/ParseHelpers/parentHelper";
import * as actionTypes from "./actionTypes";
import * as ParentAction from "./actions";

function* getParentForObjectIdSaga({ payload: { objectId } }) {
  try {
    const response = yield call(ParentHelper.getParentForObjectId, objectId);
    yield put(ParentAction.setLoading(false));

    if (response) {
      yield put(ParentAction.updateUserObjectParent(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetParentForObjectIdSaga() {
  yield takeEvery(
    actionTypes.GET_PARENT_FOR_OBJECTID,
    getParentForObjectIdSaga
  );
}

function* parentSaga() {
  yield all([fork(watchGetParentForObjectIdSaga)]);
}
export default parentSaga;
