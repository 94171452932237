import * as actions from "./actionTypes";

const initialState = {
  afterSaveWebcodeObject: undefined,
  isLoadingWebcode: true,
};

const LearningActivity = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING:
      state = { ...state, isLoadingWebcode: action.payload.boolValue };
      break;

    case actions.UPDATE_WEBCODE_OBJECT:
      state = {
        ...state,
        afterSaveWebcodeObject: action.payload.afterSaveWebcodeObject,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LearningActivity;
