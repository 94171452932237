import Parse from "parse";

const getSchoolForObjectId = (objectId) => {
  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("School");
      query.equalTo("objectId", objectId);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};
const getSchoolsForInstituteId = (instituteId) => {
  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("School");
      query.equalTo("instituteId", instituteId);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export { getSchoolForObjectId, getSchoolsForInstituteId };
