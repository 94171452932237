import Parse from "parse";

const getLearningActivitiesForIds = (activityIds) => {
  try {
    return new Promise((resolve, reject) => {
      var LearningActivity = Parse.Object.extend("LearningActivity");
      var query = new Parse.Query(LearningActivity);
      query.containedIn("objectId", activityIds);
      query.ascending("orderBy");
      query.notEqualTo("isDeleted", true);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};
const getLearningActivitiesForCategoryIds = (categoryIds) => {
  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("LearningActivity");
      query.containedIn("category", categoryIds);
      query.ascending("orderBy");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export { getLearningActivitiesForIds, getLearningActivitiesForCategoryIds };
