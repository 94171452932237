import * as actions from "./actionTypes";

const initialState = {
  categories: undefined,
  isLoadingCategories: true,
};

const LearningCategory = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING:
      state = { ...state, isLoadingCategories: action.payload.boolValue };
      break;

    case actions.UPDATE_CATEGORIES:
      state = {
        ...state,
        categories: action.payload.categories,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LearningCategory;
