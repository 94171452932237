import * as actions from "./actionTypes";

const initialState = {
  fileObjects: [],
  isLoadingFile: true,
};

const File = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING:
      state = { ...state, isLoadingFile: action.payload.boolValue };
      break;

    case actions.UPDATE_FILES_FOR_FOLDER_IDS:
      state = {
        ...state,
        fileObjects: action.payload.fileObjects,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default File;
