import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as TeacherHelper from "../../helpers/ParseHelpers/teacherHelper";
import * as actionTypes from "./actionTypes";
import * as TeacherAction from "./actions";

function* getTeacherForObjectIdSaga({ payload: { objectId } }) {
  try {
    const response = yield call(TeacherHelper.getTeacherForObjectId, objectId);
    yield put(TeacherAction.setLoading(false));

    if (response) {
      yield put(TeacherAction.updateUserObjectTeacher(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetTeacherForObjectIdSaga() {
  yield takeEvery(
    actionTypes.GET_TEACHER_FOR_OBJECT_ID,
    getTeacherForObjectIdSaga
  );
}

function* teacherSaga() {
  yield all([fork(watchGetTeacherForObjectIdSaga)]);
}
export default teacherSaga;
