import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as OnlineLearningTrackingHelper from "../../helpers/ParseHelpers/onlineLearningTrackingHelper";
import * as actionTypes from "./actionTypes";
import * as OnlineLearningTrackingActions from "./actions";

function* getOnlineLearningTrackingsForKidIdSaga({ payload: { kidId } }) {
  try {
    const response = yield call(
      OnlineLearningTrackingHelper.getOnlineLearningTrackingsForKidId,
      kidId
    );
    yield put(OnlineLearningTrackingActions.setLoading(false));

    if (response) {
      yield put(
        OnlineLearningTrackingActions.updateOnlineLearningTrackingsForKidId(
          response
        )
      );
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetOnlineLearningTrackingsForKidIdSaga() {
  yield takeEvery(
    actionTypes.GET_ONLINE_LEARNING_TRACKINGS_FOR_KID_ID,
    getOnlineLearningTrackingsForKidIdSaga
  );
}

function* onlineLearningTrackingSaga() {
  yield all([fork(watchGetOnlineLearningTrackingsForKidIdSaga)]);
}
export default onlineLearningTrackingSaga;
