import * as actions from "./actionTypes";

export const setLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const getRemindersForLiveMeeting = (
  kidId,
  classId,
  schoolId,
  groupIds,
  familyId
) => {
  return {
    type: actions.GET_REMINDERS_FOR_LIVE_MEETING,
    payload: { kidId, classId, schoolId, groupIds, familyId },
  };
};

export const updateRemindersForLiveMeeting = (reminderObjects) => {
  return {
    type: actions.UPDATE_REMINDERS_FOR_LIVE_MEETING,
    payload: { reminderObjects },
  };
};
