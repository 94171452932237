import * as actions from "./actionTypes";

export const setLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const getCategoriesForCurriculumId = (curriculumId) => {
  return {
    type: actions.GET_LEARNING_CATEGORY_FOR_CURRICULUM_ID,
    payload: { curriculumId },
  };
};

export const updateCategories = (categories) => {
  return {
    type: actions.UPDATE_CATEGORIES,
    payload: { categories },
  };
};
