import * as actions from "./actionTypes";

const initialState = {
  liveMeetingObjects: undefined,
  isLoadingLiveMeeting: true,
};

const LiveMeeting = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING:
      state = { ...state, isLoadingLiveMeeting: action.payload.boolValue };
      break;

    case actions.UPDATE_LIVE_MEETINGS_FOR_KID:
      state = {
        ...state,
        liveMeetingObjects: action.payload.liveMeetingObjects,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LiveMeeting;
