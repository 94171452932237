import * as actions from "./actionTypes";

export const setLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const getSchoolForObjectId = (objectId) => {
  return {
    type: actions.GET_SCHOOL_FOR_OBJECT_ID,
    payload: { objectId },
  };
};
export const getSchoolsForInstituteId = (instituteId) => {
  return {
    type: actions.GET_SCHOOLS_FOR_INSTITUTE_ID,
    payload: { instituteId },
  };
};

export const updateSchools = (schools) => {
  return {
    type: actions.UPDATE_SCHOOLS,
    payload: { schools },
  };
};
