import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class StatBar extends Component {
  render() {
    return (
      <Col
        md="12"
        xs="12"
        style={{ marginTop: 75, marginBottom: -95 }}
        className="justify-content-center p-0"
      >
        <div className="bg-light  p-3">
          <h2 className="text-dark " style={{ textAlign: "center" }}>
            {this.props.statTitle}
          </h2>
        </div>
      </Col>

      // <section className="bg-half-10 bg-muted ">
      //      <Row className="justify-content-center">
      //                 {/* <Col lg="12" className="text-center">
      //                     <div className="page-next-level">

      //                     </div>
      //                 </Col>   */}

      //             { <h4 className="title"> {"Cart"}</h4> }

      //     </Row>
      // </section>
    );
  }
}

export default StatBar;
