import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as SchoolHelper from "../../helpers/ParseHelpers/schoolHelper";
import * as actionTypes from "./actionTypes";
import * as SchoolAction from "./actions";

function* getSchoolForObjectIdSaga({ payload: { objectId } }) {
  try {
    const response = yield call(SchoolHelper.getSchoolForObjectId, objectId);
    yield put(SchoolAction.setLoading(false));

    if (response) {
      yield put(SchoolAction.updateSchools(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getSchoolsForInstituteIdSaga({ payload: { instituteId } }) {
  try {
    const response = yield call(
      SchoolHelper.getSchoolsForInstituteId,
      instituteId
    );
    yield put(SchoolAction.setLoading(false));

    if (response) {
      yield put(SchoolAction.updateSchools(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetSchoolForObjectIdSaga() {
  yield takeEvery(
    actionTypes.GET_SCHOOL_FOR_OBJECT_ID,
    getSchoolForObjectIdSaga
  );
}
export function* watchGetSchoolsForInstituteIdSaga() {
  yield takeEvery(
    actionTypes.GET_SCHOOLS_FOR_INSTITUTE_ID,
    getSchoolsForInstituteIdSaga
  );
}

function* schoolSaga() {
  yield all([
    fork(watchGetSchoolForObjectIdSaga),
    fork(watchGetSchoolsForInstituteIdSaga),
  ]);
}
export default schoolSaga;
