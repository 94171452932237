import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import ecommerceSaga from "./e-commerce/saga";
import calendarSaga from "./calendar/saga";
import chatSaga from "./chat/saga";
import cryptoSaga from "./crypto/saga";
import invoiceSaga from "./invoices/saga";
import projectsSaga from "./projects/saga";
import mailsSaga from "./mails/saga";
import tasksSaga from "./tasks/saga";
import contactsSaga from "./contacts/saga";
import onlineLearningSessionSaga from "./onlineLearningSession/saga";
import learningActivity from "./learningActivity/saga";
import webcode from "./webcode/saga";
import parent from "./parent/saga";
import kid from "./kid/saga";
import teacher from "./teacher/saga";
import files from "./files/saga";
import school from "./school/saga";
import mediaCategories from "./mediaCategories/saga";
import learningCategory from "./learningCategory/saga";
import onlineLearningTracking from "./onlineLearningTracking/saga";
import quiz from "./quiz/saga";
import groupMap from "./groupMap/saga";
import reminder from "./reminder/saga";
import liveMeeting from "./liveMeeting/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(mailsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(onlineLearningSessionSaga),
    fork(learningActivity),
    fork(webcode),
    fork(parent),
    fork(kid),
    fork(teacher),
    fork(files),
    fork(school),
    fork(mediaCategories),
    fork(learningCategory),
    fork(onlineLearningTracking),
    fork(quiz),
    fork(groupMap),
    fork(reminder),
    fork(liveMeeting),
  ]);
}
