import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardImg,
  Label,
} from "reactstrap";
import * as Env from "../../environments";
import { connect } from "react-redux";

import { withTranslation } from "react-i18next";
import { matchPath } from "react-router";
import QRCode from "qrcode";
import Parse from "parse";

import logo from "../../assets/images/logo-dark.png";
import { v4 as uuidv4 } from "uuid";
import {
  saveUUIDToWebCode,
  getParentForObjectId,
  getKidsForFamilyId,
  getTeacherForObjectId,
  getProfileKidForObjectId,
} from "../../store/actions";

const Index = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/learning",
    exact: true,
    strict: false,
  });

  const [loading, setLoading] = useState(false);
  const [formQRCode, setFormQRCode] = useState(undefined);

  const [school, setSchool] = useState(undefined);

  const LEARNING_CODE = "<LEARN>" + uuidv4();

  useEffect(() => {
    checkForLogin();
  }, []);

  useEffect(() => {
    if (props.afterSaveWebcodeObject) {
      loadLiveQuery(props.afterSaveWebcodeObject);
    }
  }, [props.afterSaveWebcodeObject]);

  useEffect(() => {
    if (props.profileKids && props.profileKids.length > 0) {
      let kids = props.profileKids;
      if (kids.length === 1) {
        props.history.push(
          "/learningActivityView/" +
            kids[0].attributes.SchoolID +
            "/" +
            kids[0].id
        );
      } else {
        let profileList = [];
        for (var i in kids) {
          var temp = {};
          temp.id = kids[i].id;
          temp.name = kids[i].attributes.Name;
          if (kids[i].attributes.Photo) {
            temp.photoURL = kids[i].attributes.Photo._url;
          } else {
            if (kids[i].attributes.Gender === "Male") {
              //temp.photoURL = "images/he.png";
            } else {
              //temp.photoURL = "images/she.png";
            }
          }
          profileList.push(temp);
        }

        props.history.push(
          "/learningChooseProfile/" +
          kids[0].attributes.FamilyID
        );
      }
    }
    if (props.profileKids && props.profileKids.length === 0) {
      localStorage.clear();
      Parse.User.logOut();
      var rememberUntil = 0;
      window.localStorage.setItem("remeberUntil", rememberUntil);
      window.location.reload();
    }
  }, [props.profileKids]);

  useEffect(() => {
    if (props.userObjectTeacher) {
      if (props.userObjectTeacher.length > 0) {
        let teacher = props.userObjectTeacher[0];
        props.history.push(
          "/learningActivityView/" +
            (teacher.attributes.SchoolID ?? teacher.attributes.instituteId) +
            "/" +
            teacher.id
        );
      } else {
        
        let currentUser = Parse.User.current();
        if (currentUser) {
          props.getProfileKidForObjectId(currentUser.attributes.username);
        }
      }
    }
  }, [props.userObjectTeacher]);



  useEffect(() => {
    if (props.userObjectParent) {
      if (props.userObjectParent.length > 0) {
        let parent = props.userObjectParent[0];

        props.getKidsForFamilyId(parent.attributes.FamilyID);
      } else {
        let currentUser = Parse.User.current();
        if (currentUser) {
          props.getTeacherForObjectId(currentUser.attributes.username);
        }
      }
    }
  }, [props.userObjectParent]);

  const checkForLogin = () => {
    let currentUser = Parse.User.current();
    if (!currentUser) {
      formQr();
      props.saveUUIDToWebCode(LEARNING_CODE);
    } else {
      checkForUserAndLoad();
    }
  };

  const checkForUserAndLoad = () => {
    let currentUser = Parse.User.current();
    if (currentUser) {
      props.getParentForObjectId(currentUser.attributes.username);
    }
  };

  const loadLiveQuery = (obj) => {
    var client = new Parse.LiveQueryClient({
      applicationId: Env.APPLICATION_ID,
      serverURL: "wss://liltriangle.back4app.io",
      javascriptKey: Env.JAVASCRIPT_KEY,
    });
    client.open();

    var query = new Parse.Query("WebCode");
    query.equalTo("objectId", obj.id);
    var subscription = client.subscribe(query);
    Parse.LiveQuery.on("error", (error) => {
      console.log(error);
      window.location.reload();
    });
    subscription.on("update", (updatedData) => {
      if (updatedData.attributes.userId) {
        // $("#page_loader").show();
        // console.log(updatedData)
        if (!updatedData.attributes.handShakeCode) {
          subscription.on("close", () => {
            Parse.User.logIn(
              updatedData.attributes.userId,
              updatedData.attributes.userId
            )
              .then((user) => {
                if (updatedData.attributes.handShakeCode != 1) {
                  updatedData.set("handShakeCode", 1);
                  updatedData.save().then((result) => {
                    var schedulehours = new Date();
                    //set sessiong for 30 sec
                    schedulehours.setHours(schedulehours.getHours() + 24 * 7);
                    var rememberUntil = Math.round(
                      (schedulehours.getTime() - Date.UTC(1970, 0, 1)) / 1000
                    );
                    window.localStorage.setItem("remeberUntil", rememberUntil);
                    window.location.reload();
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                updatedData.set("handShakeCode", 0);
                updatedData.save().then((result) => {
                  window.location.reload();
                });
              });
          });
          subscription.unsubscribe();
          Parse.LiveQuery.close();
        }
      }
    });
  };

  function formQr() {
    QRCode.toDataURL(LEARNING_CODE, { errorCorrectionLevel: "H" })
      .then((url) => {
        setFormQRCode(url);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <React.Fragment>
      <Row className=" m-0 p-0">
        {/*justify-content-center text-center*/}
        <Col xs="12" className=" m-0 p-0">
          <div style={{ width: "100p%", height: "1000px" }}>
            <div style={{ height: "100px", backgroundColor: "#E7E4FB" }}>
              <img
                className="img-responsive "
                src={logo}
                style={{
                  width: "150px",
                  marginTop: "3%",
                  marginLeft: "11%",
                }}
              />
            </div>

            <div style={{ height: "150px", backgroundColor: "#E7E4FB" }}></div>
            <div style={{ height: "3px", backgroundColor: "#FF007D" }}></div>
            <div style={{ height: "750px", backgroundColor: "#F7F7F7" }}></div>
            <center>
              <div
                style={{
                  backgroundColor: "#fff",
                  top: "120px",
                  position: "absolute",
                  width: "70%",
                  left: "50%",
                  transform: "translate(-50%, 0)",

                  border: "0.5px solid #FF007D40",
                }}
              >
                <div>
                  <div className="col-md-12">
                    <div
                      style={{
                        backgroundColor: "#fff",
                        marginTop: "6%",

                        height: " 100%",
                      }}
                      className=" d-flex"
                    >
                      <div
                        className=""
                        style={{ marginLeft: "5%", textAlign: "left" }}
                      >
                        <label className="mt-3" style={{ fontSize: "20px" }}>
                          To use lilTriangle on your computer:
                        </label>
                        <ol style={{ fontSize: "16px" }}>
                          <li className="mt-3 mb-3">
                            Open lilTriangle App on your phone (if not logged in
                            please login)
                          </li>
                          <li className="mt-3 mb-3">
                            Tap on the Scan Button which is on bottom right
                            corner
                          </li>
                          <li className="mt-3 mb-3">
                            Point your phone camera to this screen to capture
                            the code
                          </li>
                        </ol>
                      </div>
                      <div className=" float-right">
                        <img
                          className="img-responsive"
                          src={formQRCode}
                          style={{ width: "300px", marginTop: "1%" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-12 mt-5 mb-5"
                    style={{ marginRight: "5%" }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <Label
                        style={{
                          color: "#FF007D",
                          fontSize: "16px",
                          marginLeft: "10%",
                        }}
                      >
                        Need help to get start?
                      </Label>
                    </div>
                    <iframe
                      width="80%"
                      height="400px"
                      src="https://www.youtube.com/embed/ZrTGzlZywfA?rel=0"
                      name="iframe_a"
                      id="idfocus_me"
                      allowFullScreen={true}
                    >
                      <p>Your browser does not support iframes.</p>
                    </iframe>
                  </div>
                </div>
              </div>
            </center>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { afterSaveWebcodeObject } = state.webcode;
  const { userObjectParent } = state.parent;
  const { profileKids } = state.kid;
  const { userObjectTeacher } = state.teacher;

  return {
    afterSaveWebcodeObject,
    userObjectParent,
    profileKids,
    userObjectTeacher,
  };
};

export default withTranslation()(
  withRouter(
    connect(mapStatetoProps, {
      saveUUIDToWebCode,
      getParentForObjectId,
      getKidsForFamilyId,
      getTeacherForObjectId,
      getProfileKidForObjectId,
    })(Index)
  )
);
