import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as FilesHelper from "../../helpers/ParseHelpers/filesHelper";
import * as actionTypes from "./actionTypes";
import * as FilesAction from "./actions";

function* getFilesForFolderIdsSaga({ payload: { folderIds } }) {
  try {
    const response = yield call(FilesHelper.getFilesForFolderIds, folderIds);
    yield put(FilesAction.setLoading(false));

    if (response) {
      yield put(FilesAction.updateFilesForFolderIds(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetFilesForFolderIdsSaga() {
  yield takeEvery(
    actionTypes.GET_FILES_FOR_FOLDER_IDS,
    getFilesForFolderIdsSaga
  );
}

function* fileSaga() {
  yield all([fork(watchGetFilesForFolderIdsSaga)]);
}
export default fileSaga;
