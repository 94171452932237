import Parse from "parse";

const getGroupMapsForUserId = (userId) => {
  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("GroupMap");
      query.equalTo("userId", userId);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export { getGroupMapsForUserId };
