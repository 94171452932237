module.exports = {
  // Production Keys
  APPLICATION_ID: "n1BIxpDhrdEUUwxqMla37DBZoMlFIWLRVLVKXK0a",
  JAVASCRIPT_KEY: "kksCy9sGTpV2SKEFTGhcI53DOyGqqmWdSuBDU1KU",

  // Deveopment Keys
  // APPLICATION_ID: "0ZRsOIsU32VP5AhOrDR7nMqrGR7PFkzaSYEPBsNN",
  // JAVASCRIPT_KEY: "DKg1ZmRPFbXuwgZYWQwSWMvdF1UGwIZAdBDjTDM4",
  //if need change server url for locla
  SERVER_URL: "https://parseapi.back4app.com/",
  // SERVER_URL: "http://localhost:1337/parse",
};
