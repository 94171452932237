import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import Zoom from "./zoom/ConnectZoom";

import {
  Col,
  Label,
  Modal,
  Row,
  ModalBody,
  ModalFooter,
  Button,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import toastr from "toastr";

import { connect } from "react-redux";

import { withTranslation } from "react-i18next";
import { matchPath } from "react-router";

import Parse from "parse";

import StatBar from "../../components/Common/statbar";
import SkeletonComponent from "../../components/Common/SkeletonComponent";

import CourseContent from "./CourseContent";

import {
  getOnlineLearningSessionForKidIds,
  getLearningActivitiesForIds,
  getFilesForFolderIds,
  getTeacherForObjectId,
  getSchoolForObjectId,
  getSchoolsForInstituteId,
  getMediaCategoriesForTargetAudienceIds,
  getCategoriesForCurriculumId,
  getLearningActivitiesForCategoryIds,
  getOnlineLearningTrackingsForKidId,
  getQuizForActivityIds,
  getKidForObjectId,
  getGroupMapsForUserId,
  getRemindersForLiveMeeting,
  getLiveMeetingsForKid,
} from "../../store/actions";
import courseImage from "../../assets/images/course.png";

const LearningActivityView = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/learningActivityView/:schoolId/:userId",
    exact: true,
    strict: false,
  });

  const userId = match && match.params.userId;
  var activeSchoolId = match && match.params.schoolId;

  const imgFormat = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];
  const fileFormat = [
    ".pdf",
    ".xlsx",
    ".xls",
    ".ppt",
    ".pptx",
    ".doc",
    ".docx",
    ".mp3",
    "mp3",
    ".wma",
    "wma",
    ".acc",
    "acc",
    ".wav",
    "wav",
    ".flac",
    "flac",
    "pdf",
    "xlsx",
    "xls",
    "ppt",
    "pptx",
    "doc",
    "docx",
  ];

  const [loading, setLoading] = useState(true);
  const [showMeetingJoinPopup, setShowMeetingJoinPopup] = useState(false);
  var userObject = undefined;
  const [reminderObjectState, setReminderObjectState] = useState([]);
  const [showSelectCourseModel, setShowSelectCourseModel] = useState(false);
  const [formQRCode, setFormQRCode] = useState(undefined);
  const [isModelOpen, setIsModelOpen] = useState(true);
  const [selectedActivity, setSelectedActivity] = useState(undefined);
  const [selectedResource, setSelectedResource] = useState(undefined);

  const [isKid, setIsKid] = useState(false);

  const [userObjectState, setUserObjectState] = useState(undefined);

  const [noContent, setNoContent] = useState(false);
  const [courseObject, setCourseObject] = useState(undefined);
  const [learningCategories, setLearningCategories] = useState([]);
  const [meetingAccess, setMeetingAccess] = useState(false);
  const [isFreeBatch, setIsFreeBatch] = useState(false);
  const [masterActivities, setMasterActivities] = useState([]);
  const [meetingDetails, setMeetingDetails] = useState([]);
  const [groupIds, setGroupIds] = useState([]);

  useEffect(() => {
    checkForLoginAndLoad();
  }, []);

  // useEffect(() => {
  //   if (props.onlineLearningSessionObjects) {
  //     if (props.onlineLearningSessionObjects.length > 0) {
  //       let results = [...props.onlineLearningSessionObjects];
  //       var activitesList = [];
  //       let tempMasterActivities = [];
  //       for (var i in results) {
  //         if (!activitesList.includes(results[i].attributes.activityId)) {
  //           var temp = [];
  //           temp.id = results[i].id;
  //           temp.obj = results[i];
  //           temp.date = results[i].createdAt;
  //           temp.activityId = results[i].attributes.activityId;
  //           tempMasterActivities.push(temp);
  //           activitesList.push(results[i].attributes.activityId);
  //           if (!activeSchoolId) {
  //             activeSchoolId = results[i].attributes.schoolId;
  //           }
  //         }
  //       }
  //       setIsKid(true);

  //       setMasterActivities(tempMasterActivities);
  //       loadLearningActivity(activitesList, tempMasterActivities);
  //     } else {
  //       let currentUser = Parse.User.current();
  //       if (currentUser) {
  //         props.getTeacherForObjectId(currentUser.attributes.username);
  //       }
  //     }
  //   }
  // }, [props.onlineLearningSessionObjects]);

  useEffect(() => {
    if (props.onlineLearningTrackingObjects) {
      if (props.onlineLearningTrackingObjects.length > 0) {
        let results = [...props.onlineLearningTrackingObjects];
        var activitesList = [];
        let tempMasterActivities = [];
        for (var i in results) {
          if (!activitesList.includes(results[i].attributes.activityId)) {
            var temp = {};
            temp.id = results[i].id;
            temp.obj = results[i];
            temp.date = results[i].createdAt;
            temp.activityId = results[i].attributes.activityId;
            temp.parseObjectTracking = results[i];
            temp.ratingValue = results[i].attributes.ratingValue ?? 5;
            temp.time = results[i].createdAt;
            tempMasterActivities.push(temp);
            activitesList.push(results[i].attributes.activityId);
            if (!activeSchoolId) {
              activeSchoolId = results[i].attributes.schoolId;
            }
          }
        }
        setIsKid(true);

        setMasterActivities(tempMasterActivities);
        loadLearningActivity(activitesList, tempMasterActivities);
      } else {
        let currentUser = Parse.User.current();
        if (currentUser) {
          props.getTeacherForObjectId(currentUser.attributes.username);
        }
      }
    }
  }, [props.onlineLearningTrackingObjects]);

  useEffect(() => {
    if (
      props.learningActivityObjects &&
      props.learningActivityObjects.length > 0
    ) {
      let results = [...props.learningActivityObjects];

      if (isKid) {
        var resourceIds = [];
        let tempMasterActivities = [...masterActivities];
        for (var i in tempMasterActivities) {
          for (var j in results) {
            if (results[j].id === tempMasterActivities[i].activityId) {
              tempMasterActivities[i].title = results[j].attributes.name;
              tempMasterActivities[i].description =
                results[j].attributes.description;
              tempMasterActivities[i].contentList = [];

              tempMasterActivities[i].resourceFolderId =
                results[j].attributes.resourceFolderId;
              tempMasterActivities[i].showContent = 4;
              if (results[j].attributes.resourceFolderId) {
                resourceIds.push(results[j].attributes.resourceFolderId);
              }
              break;
            }
          }
        }
        setMasterActivities(tempMasterActivities);
        loadResources(resourceIds, tempMasterActivities);
      } else {
        let tempmasterActivities = [];
        var resourceIds = [];
        for (var i in results) {
          let temp = {};
          temp.id = results[i].id;
          temp.title = results[i].attributes.name;
          temp.description = results[i].attributes.description;
          temp.contentList = [];
          temp.time = results[i].createdAt;
          temp.resourceFolderId = results[i].attributes.resourceFolderId;
          temp.showContent = 4;
          if (results[i].attributes.resourceFolderId) {
            resourceIds.push(results[i].attributes.resourceFolderId);
          }
          tempmasterActivities.push(temp);
        }
        setMasterActivities(tempmasterActivities);
        loadResources(resourceIds, tempmasterActivities);
      }
    }
  }, [props.learningActivityObjects]);

  useEffect(() => {
    if (props.schools) {
      if (props.schools.length > 0) {
        let schools = [...props.schools];
        activeSchoolId = schools[0].id;
        var temp = [];
        if (userObjectState) {
          temp.push(userObjectState.attributes.instituteId);
        } else if (userObject) {
          temp.push(userObject.attributes.instituteId);
        }
        for (var i in schools) {
          temp.push(schools[i].id);
          if (schools[i].attributes.franchiseId) {
            temp.push(schools[i].attributes.franchiseId);
          }
        }
        loadDetailsForTeacher(temp);
      }
    }
  }, [props.schools, userObjectState]);

  useEffect(() => {
    if (props.userObjectTeacher) {
      if (props.userObjectTeacher.length > 0) {
        let teacher = props.userObjectTeacher[0];
        if (teacher) {
          let userObj = teacher;
          setUserObjectState(teacher);
          userObject = teacher;
          if (userObj.className === "Teacher") {
            setIsKid(false);

            if (userObj.attributes.SchoolID) {
              props.getSchoolForObjectId(userObj.attributes.SchoolID);
            } else if (userObj.attributes.instituteId) {
              props.getSchoolsForInstituteId(userObj.attributes.instituteId);
            }
          }
        }
      } else {
        setLoading(false);
      }
    }
  }, [props.userObjectTeacher]);

  useEffect(() => {
    if (props.mediaCategories) {
      if (props.mediaCategories.length > 0) {
        let mediaCats = [...props.mediaCategories];
        if (mediaCats.length === 1) {
          loadForThisCourse(mediaCats[0].attributes.mediaContentId);
        } else {
          setShowSelectCourseModel(true);
        }
      }
    }
  }, [props.mediaCategories]);

  useEffect(() => {
    if (props.categories) {
      if (props.categories.length > 0) {
        var categoryIds = [];
        for (var i in props.categories) {
          categoryIds.push(props.categories[i].id);
        }
        props.getLearningActivitiesForCategoryIds(categoryIds);
      }
    }
  }, [props.categories]);

  const loadDetailsForTeacher = (targetAudienceIds) => {
    props.getMediaCategoriesForTargetAudienceIds(targetAudienceIds);
  };

  const loadForThisCourse = (item) => {
    props.getCategoriesForCurriculumId(item);
  };

  const checkForLoginAndLoad = () => {
    let currentUser = Parse.User.current();
    if (!currentUser) {
      props.history.push("/login");
    } else {
      let tempKidIds = [];
      tempKidIds.push(userId ?? "");
      //props.getOnlineLearningSessionForKidIds(tempKidIds);
      props.getOnlineLearningTrackingsForKidId(userId);
      props.getKidForObjectId(userId);
    }
  };

  useEffect(() => {
    if (props.userObjectKid) {
      if (props.userObjectKid.length > 0) {
        setIsKid(true);
        let result = props.userObjectKid[0];
        if (result) {
          userObject = result;
          setUserObjectState(result);
          props.getGroupMapsForUserId(result.id);
        }
      }
    }
  }, [props.userObjectKid]);

  useEffect(() => {
    if (props.groupMapObjects) {
      let results = props.groupMapObjects;
      let tempGroupIds = [];
      for (var i in results) {
        tempGroupIds.push(results[i].attributes.groupId);
      }
      setGroupIds(tempGroupIds);
      loadLiveMeeting();
    }
  }, [props.groupMapObjects]);

  const loadLiveMeeting = () => {
    if (userObjectState) {
      props.getRemindersForLiveMeeting(
        userId,
        userObjectState.attributes.ClassRoomID ??
        userObject.attributes.ClassRoomID,
        userObjectState.attributes.SchoolID ?? userObject.attributes.SchoolID,
        groupIds,
        userObjectState.attributes.FamilyID ?? userObject.attributes.FamilyID
      );
    } else if (userObject) {
      props.getRemindersForLiveMeeting(
        userId,

        userObject.attributes.ClassRoomID,
        userObject.attributes.SchoolID,
        groupIds,
        userObject.attributes.FamilyID
      );
    }
  };

  useEffect(() => {
    if (props.reminderObjects) {
      let results = props.reminderObjects;
      let currentDate = new Date();
      let startTime = new Date(currentDate.setHours(0, 0, 0, 0));
      let endTime = new Date(currentDate.setHours(23, 59, 59, 999));

      let reminderObj = [];
      var lmIds = [];
      for (let i in results) {
        lmIds.push(results[i].attributes.liveMeetingId);
        reminderObj[results[i].attributes.liveMeetingId] = results[i];
      }
      setReminderObjectState(reminderObj);
      var recipientIds = [];
      recipientIds.push(userId);
      if (
        (userObjectState && userObjectState.attributes.ClassRoomID) ||
        (userObject && userObject.attributes.ClassRoomID)
      ) {
        recipientIds.push(
          userObjectState
            ? userObjectState.attributes.ClassRoomID
            : userObject.attributes.ClassRoomID
        );
      }
      if (groupIds.length > 0) {
        for (var i in groupIds) {
          recipientIds.push(groupIds[i]);
        }
      }
      if (
        (userObjectState && userObjectState.attributes.SchoolID) ||
        (userObject && userObject.attributes.SchoolID)
      ) {
        recipientIds.push(
          userObjectState
            ? userObjectState.attributes.SchoolID
            : userObject.attributes.SchoolID
        );
      }
      props.getLiveMeetingsForKid(recipientIds, lmIds);
    }
  }, [props.reminderObjects]);

  useEffect(() => {
    if (props.liveMeetingObjects) {
      let results = props.liveMeetingObjects;
      if (results && results.length > 0) {
        let tempMeetingDetails = [];
        for (var i in results) {
          var temp = {};
          temp.type = results[i].attributes.medium;
          temp.meetingId = results[i].attributes.meetingId;
          temp.pass = results[i].attributes.password;
          if (reminderObjectState[results[i].id]) {
            temp.time =
              reminderObjectState[results[i].id].attributes.reminderTime;
          } else {
            temp.time = results[i].createdAt;
          }
          //https://zoom.us/j/98918253609?pwd=ZVdpVG5P
          temp.troubleUrl =
            "https://zoom.us/j/" + temp.meetingId + "?pwd=" + temp.pass;
          temp.url =
            "https://liltriangle.com/zoom/#/" +
            temp.meetingId +
            "/" +
            temp.pass +
            "/" +
            (userObjectState
              ? userObjectState.attributes.Name
              : userObject.attributes.Name);
          temp.parseObject = results[i];
          temp.id = results[i].id;
          tempMeetingDetails.push(temp);
        }
        setMeetingDetails(tempMeetingDetails);
      }
    }
  }, [props.liveMeetingObjects]);

  function youtube_parser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  useEffect(() => {
    if (props.fileObjects) {
      let results = [...props.fileObjects];
      let tempMasterActivities = [...masterActivities];
      if (props.fileObjects.length > 0) {
        for (var i in tempMasterActivities) {
          let tempContentList = [];
          for (var j in results) {
            if (
              tempMasterActivities[i].resourceFolderId ===
              results[j].attributes.folderId
            ) {
              if (results[j].attributes.fileType == "vimeo") {
                var temp = {};
                temp.name = results[j].attributes.name;
                temp.type = "vimeo";
                var rawUrl = results[j].attributes.url.split("/");
                var frameUrl = "https://player.vimeo.com/video/" + rawUrl[3]+"?h="+rawUrl[4];
                temp.url = frameUrl;
                temp.previewImage = results[j].attributes.thumbnailUrl;
                temp.parseObject = results[j];
                temp.id = results[j].id;
                tempContentList.push(temp);
              } else if (results[j].attributes.fileType == "youtube") {
                var temp = {};

                temp.name = results[j].attributes.name;
                let youtubeId = youtube_parser(results[j].attributes.url);
                temp.url = "https://www.youtube.com/embed/" + youtubeId;
                temp.type = "youtube";

                temp.id = results[j].id;
                temp.parseObject = results[j];

                tempContentList.push(temp);
              } else if (
                results[j].attributes.fileType == "url" &&
                results[j].attributes.url
              ) {
                var temp = {};

                if (results[j].attributes.url.includes("vimeo.com")) {
                  temp.name = results[j].attributes.name;
                  temp.type = "vimeo";
                  var rawUrl = results[j].attributes.url.split("/");
                  var frameUrl = "https://player.vimeo.com/video/" + rawUrl[3]+"?h="+rawUrl[4];
                  temp.url = frameUrl;
                  temp.previewImage = results[j].attributes.thumbnailUrl;
                } else if (
                  results[j].attributes.url.includes("youtube.com") ||
                  results[j].attributes.url.includes("youtu.")
                ) {
                  temp.name = results[j].attributes.name;
                  let youtubeId = youtube_parser(results[j].attributes.url);
                  temp.url = "https://www.youtube.com/embed/" + youtubeId;
                  temp.type = "youtube";
                } else {
                  temp.name = results[j].attributes.name;
                  temp.url = results[j].attributes.url;
                  temp.type = "url";
                }
                temp.id = results[j].id;
                temp.parseObject = results[j];

                tempContentList.push(temp);
              } else if (results[j].attributes.file) {
                var temp = {};
                let tempName = results[j].attributes.file._name;
                temp.name = tempName.substring(tempName.indexOf("_") + 1);
                temp.url = results[j].attributes.file._url;
                var typeD = results[j].attributes.file._name.split(".");
                if (imgFormat.includes(typeD[typeD.length - 1])) {
                  temp.type = "img";
                } else if (fileFormat.includes(typeD[typeD.length - 1])) {
                  temp.type = typeD[typeD.length - 1];
                }
                temp.id = results[j].id;
                temp.parseObject = results[j];

                tempContentList.push(temp);
              }
            }
            if (i === 0 && j === 0) {
              if (tempMasterActivities && tempMasterActivities.length > 0) {
                setSelectedActivity(tempMasterActivities[0]);
              }
              if (tempContentList && tempContentList.length > 0) {
                setSelectedResource(tempContentList[0]);
              }
            }
          }
          tempMasterActivities[i].contentList = [...tempContentList];
        }

        setMasterActivities(tempMasterActivities);
      }

      loadQuiz(tempMasterActivities);
    }
  }, [props.fileObjects]);

  const loadLearningActivity = (activitiesList) => {
    props.getLearningActivitiesForIds(activitiesList);
  };

  const loadResources = (resourceIds, tempMasterActivities1) => {
    props.getFilesForFolderIds(resourceIds);
  };

  const loadQuiz = (tempMasterActivities) => {
    var learningActivityIds = [];
    for (var i in tempMasterActivities) {
      learningActivityIds.push(tempMasterActivities[i].activityId);
    }
    if (learningActivityIds.length > 0) {
      props.getQuizForActivityIds(learningActivityIds);
    }
  };

  useEffect(() => {
    if (props.quizObjects) {
      let quizs = props.quizObjects;
      if (quizs && quizs.length > 0) {
        let type;
        let tempMasterActivities = [...masterActivities];
        if (userObjectState && userObjectState.className === "Teacher") {
          type = 1;
        } else if (userObject && userObject.className === "Teacher") {
          type = 1;
        } else {
          type = 2;
        }

        for (var i in quizs) {
          for (var j in tempMasterActivities) {
            if (
              quizs[i].attributes.activityId ===
              tempMasterActivities[j].activityId
            ) {
              var temp = {};
              temp.id = quizs[i].id;
              temp.name = quizs[i].attributes.name;
              temp.type = "quiz";
              temp.parseObject = quizs[i];
              temp.url = `https://liltriangle.com/register/#/quiz/${activeSchoolId}/${type == 1
                ? userObjectState
                  ? userObjectState.id
                  : userObject.id
                : userId
                }/${type}/${quizs[i].id}`;
              if (tempMasterActivities[j].contentList) {
                tempMasterActivities[j].contentList.push(temp);
              } else {
                tempMasterActivities[j].contentList = [temp];
              }
            }
          }
        }
        setMasterActivities(tempMasterActivities);
      }
      setLoading(false);
    }
  }, [props.quizObjects]);

  const handleOnClickResource = (resource) => {
    // if (
    //   resource &&
    //   (selectedResource == null || selectedResource.id !== resource.id)
    // ) {
    if (resource !== null) {
      setSelectedResource(resource);
    }
    // }
  };

  const handleOnClickActivity = (activity) => {
    if (
      activity &&
      (selectedActivity == null ||
        selectedActivity == null ||
        selectedActivity.id !== activity.id)
    ) {
      if (activity.contentList && activity.contentList.length > 0) {
        setSelectedResource(activity.contentList[0]);
      } else {
        setSelectedResource(undefined);
      }

      setSelectedActivity(activity);
    }
  };
  const handleOnClickComplete = (activity) => {
    let tempMasterActivities = [...masterActivities];

    let index = masterActivities.indexOf(activity);
    if (index !== -1) {
      tempMasterActivities[index].parseObjectTracking.set("ratingValue", 100);
      tempMasterActivities[index].parseObjectTracking.set(
        "rating",
        "Completed"
      );

      tempMasterActivities[index].parseObjectTracking.save().then(
        (result) => {
          if (result) {
            tempMasterActivities[index].ratingValue = 100;

            setMasterActivities(tempMasterActivities);
          }
        },
        (error) => { }
      );
    }
  };
  function copyMeetingPassword(str, event) {
    document.oncopy = function (event) {
      event.clipboardData.setData("Text", str);
      event.preventDefault();
    };
    document.execCommand("Copy");
    document.oncopy = undefined;
    toastr.success(props.t("copied to clipboard"), { timeOut: 1000 });
  }
  const API_KEYS = {
    apiKey: process.env.REACT_APP_ZOOM_API_KEY,
    apiSecret: process.env.REACT_APP_ZOOM_API_SECRET_KEY,
  };

  const zoomConfig = {
    apiKey: API_KEYS.apiKey, // can create from here https://marketplace.zoom.us/
    apiSecret: API_KEYS.apiSecret, // can create from here https://marketplace.zoom.us/
    meetingNumber: "3964087135",
    // meeting id generated when schedule a meeting in https://zoom.us/meeting/schedule
    // !!warning
    // meetingNumber must not have white space between numbers
    // 518 306 2219 - bad format
    // 5183062219 - good format
    leaveUrl: "https://zoom.us/",
    userName: "krish", // (required)
    userEmail: "shivam.patel@liltriangle.com", // (optional) must be the attendee email address
    passWord: "triangle",
    // !!warning, this is the passcode for meeting room, not for user password
    role: 0, // 0 for guest, 1 for host
  };
  const [config, setConfig] = useState(zoomConfig);

  const [isSubmitted, setIsSubmitted] = useState(true);

  return (
    <React.Fragment>
      <div className="page-content" style={{ backgroundColor: "white" }}>
        <Modal isOpen={showMeetingJoinPopup} fade={false}>
          <ModalHeader>Meeting Details</ModalHeader>
          <ModalBody>
            <div className="">
              {/* <h4 className="mt-4 font-weight-semibold text-center">
                Meeting Details
              </h4> */}
              <p className="text-muted mt-3">
                Meeting Id -{" "}
                {selectedResource ? selectedResource.meetingId : ""}
              </p>
              <div className="d-flex">
                <p className="text-muted mt-1">
                  Meeting Password -{" "}
                  {selectedResource ? selectedResource.pass : ""}{" "}
                </p>
                <div
                  style={{ cursor: "pointer" }}
                  className="text-primary float-right"
                  id="meetingPass"
                  onClick={(e) => {
                    copyMeetingPassword(
                      selectedResource ? selectedResource.pass : ""
                    );
                  }}
                >
                  <i className="bx bx-copy p-1 font-size-16"></i>
                  <UncontrolledTooltip placement="top" target="meetingPass">
                    {props.t("copy to clipboard")}
                  </UncontrolledTooltip>
                </div>
              </div>

              {/* <Col
                lg={2}
                id="meetingPass"
                onClick={(e) => {
                  copyMeetingPassword(
                    selectedResource ? selectedResource.pass : ""
                  );
                }}
              >
                <li className="list-inline-item px-2">
                  <h1 style={{ cursor: "pointer" }} className="text-primary">
                    <i className="bx bxs-copy"></i>
                  </h1>
                  <p style={{ cursor: "pointer" }}>{props.t("copy")}</p>
                  <UncontrolledTooltip placement="top" target="meetingPass">
                    {props.t("copy to clipboard")}
                  </UncontrolledTooltip>
                </li>
              </Col> */}

              {/* <Row className="justify-content-center mt-5 mb-2">
                  <Col sm="6" xs="8">
                    <div>
                      <img src={verificationImg} alt="" className="img-fluid" />
                    </div>
                  </Col>
                </Row> */}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                setShowMeetingJoinPopup(false);
              }}
            >
              Close
            </Button>
            <a
              href={selectedResource ? selectedResource.troubleUrl : ""}
              target="_blank"
            >
              {/* <Link
              to={
                "/learningZoomConnect/" +
                (selectedResource != undefined
                  ? selectedResource.meetingId
                  : "") +
                "/" +
                (selectedResource != undefined ? selectedResource.pass : "")
              }
              target="_blank"
            > */}
              <Button color="primary" onClick={() => { }}>
                Join
              </Button>
            </a>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showSelectCourseModel} fade={false} scrollable={true}>
          <ModalHeader charCode="">Select course</ModalHeader>
          <ModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Row>
                {props.mediaCategories &&
                  props.mediaCategories.length > 0 &&
                  props.mediaCategories.map((mediaCategory, key) => (
                    <Col>
                      <div
                        style={{
                          width: "220px",
                          height: "220px",
                          backgroundColor: "rgb(255,255,255)",
                        }}
                        key={key}
                        className="mr-3"
                      >
                        <div
                          onClick={() => {
                            setShowSelectCourseModel(false);
                            loadForThisCourse(
                              mediaCategory.attributes.mediaContentId
                            );
                          }}
                          className="mt-1"
                        >
                          {console.log(mediaCategory)}
                          <img
                            className="mt-4  "
                            src={
                              (mediaCategory.attributes.icon &&
                                mediaCategory.attributes.icon._url) ??
                              courseImage
                            }
                            style={{
                              width: "120px",
                              height: "120px",
                              textAlign: "center",
                            }}
                          ></img>

                          <br />

                          <h6 className="mt-3">
                            {mediaCategory.attributes &&
                              mediaCategory.attributes.title}
                          </h6>
                          <p
                            className="mt-3 text-truncate"
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {mediaCategory.attributes &&
                              mediaCategory.attributes.subTitle}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))}
              </Row>
            </div>
          </ModalBody>
          {/* <ModalFooter
            style={{ backgroundColor: "rgb(242,242,242)" }}
          ></ModalFooter> */}
        </Modal>
        {noContent ? (
          <StatBar statTitle={"No content"} />
        ) : (
          <div>
            {loading ? (
              <SkeletonComponent
                SkeletonContent={{ type: "course-progress", cellCount: 3 }}
              />
            ) : (
              <div className="row bg-white">
                <Col
                  lg={9}
                  xs={12}
                  md={9}
                  className="sidebar sticky-bar col pr-1"
                  id="playGround"
                >
                  <div style={{ height: "38.5em" }} className="bg-light">
                    {selectedResource && selectedResource.type !== "zoom" && (
                      <iframe
                        src={selectedResource.url}
                        height="500"
                        width="100%"
                        display="initial"
                        allowFullScreen={true}
                        position="relative"
                        frameBorder={0}
                      />
                    )}
                    {selectedResource && selectedResource.type === "zoom" && (
                      <div
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "35%",
                          transform: "translate(-50%, -50%)",
                        }}
                        className="text-center"
                      >
                        <Link
                          to={
                            "/learningZoomConnect/" +
                            (selectedResource != undefined
                              ? selectedResource.meetingId
                              : "") +
                            "/" +
                            (selectedResource != undefined
                              ? selectedResource.pass
                              : "") + "/" + (userObjectState != undefined ? userObjectState.attributes.Name : "User")
                          }
                          target="_blank"
                        >
                          <Button
                            color="primary"
                            className="btn btn-primary btn-lg"
                            onClick={() => { }}
                          >
                            Launch Meeting
                          </Button>
                        </Link>
                        <br />
                        <Label className="mt-2">OR</Label>
                        <br />

                        <Label
                          onClick={() => {
                            setShowMeetingJoinPopup(true);
                          }}
                          className="text-primary"
                          style={{ cursor: "pointer" }}
                        >
                          Launch via Zoom
                        </Label>

                      </div>
                      // <iframe
                      //   src={selectedResource.url}
                      //   allow="camera;microphone"
                      //   height="500"
                      //   width="100%"
                      //   display="initial"
                      //   allowFullScreen={true}
                      //   position="relative"
                      //   frameBorder={0}
                      // />
                      // <a
                      //   href={
                      //     selectedResource ? selectedResource.troubleUrl : ""
                      //   }
                      //   target="_blank"
                      // >
                      //                  <Link
                      //   to={"/learningZoomConnect/" + (selectedResource != undefined ? selectedResource.meetingId : "") + "/" + (selectedResource != undefined ? selectedResource.pass : "")}
                      //   target="_blank"
                      // > </Link>

                      // </a>
                      //  <Zoom meetConfig={config} isSubmitted={isSubmitted}></Zoom>
                    )}

                    {selectedResource === undefined &&
                      masterActivities.length > 0 && (
                        <div className="pt-3 pl-3 text-center">
                          <Label>
                            Please select an option from the list to view here
                          </Label>
                        </div>
                      )}
                  </div>

                  {selectedResource && selectedResource.type !== "zoom" && (
                    <div className="m-4">
                      <h1 className="title">
                        {selectedActivity &&
                          !isFreeBatch &&
                          selectedActivity.title}
                      </h1>
                      {selectedActivity && selectedActivity.description && (
                        <h5
                          className="para-desc text-muted"
                          style={{ "white-space": "pre-wrap" }}
                        >
                          {selectedActivity.description}
                        </h5>
                      )}
                    </div>
                  )}
                  <div className="m-4">
                    <h6 className="">
                      {selectedResource && selectedResource.name}
                    </h6>
                    {selectedResource && selectedResource.type && (
                      <h5
                        className="para-desc text-muted"
                        style={{ "white-space": "pre-wrap" }}
                      >
                        {selectedResource.type}
                      </h5>
                    )}
                    {selectedResource && selectedResource.type === "zoom" && (
                      <p style={{ fontSize: "14px" }}>
                        Having trouble joining meet ? &nbsp;
                        {/* <a href={selectedResource.troubleUrl} target="_blank">
                          click here
                        </a> */}
                        {/* <a
                          href={
                            selectedResource ? selectedResource.troubleUrl : ""
                          }
                          target="_blank"
                        > */}
                        {/* <Link
                          to={
                            "/learningZoomConnect/" +
                            (selectedResource != undefined
                              ? selectedResource.meetingId
                              : "") +
                            "/" +
                            (selectedResource != undefined
                              ? selectedResource.pass
                              : "")
                          }
                          target="_blank"
                        > */}
                        <Label
                          onClick={() => {
                            setShowMeetingJoinPopup(true);
                          }}
                          className="text-primary"
                          style={{ cursor: "pointer" }}
                        >
                          click here
                        </Label>
                        {/* </Link> */}
                      </p>
                    )}
                  </div>
                </Col>

                <Col lg={3} xs={12} md={3} className="p-0 left-col col">
                  <CourseContent
                    className="center-col"
                    courseObject={courseObject}
                    learningActivities={masterActivities}
                    selectedActivity={selectedActivity}
                    selectedResource={selectedResource}
                    onClickResource={(resource) => {
                      handleOnClickResource(resource);
                    }}
                    onClickMeeting={(meetingObject) => {
                      handleOnClickResource(meetingObject);
                    }}
                    selectedMeeting={selectedResource}
                    isKid={isKid}
                    meetingDetails={meetingDetails}
                    onClickActivity={(activity) => {
                      handleOnClickActivity(activity);
                    }}
                    handleOnClickComplete={(index) => {
                      handleOnClickComplete(index);
                    }}
                    reloadLiveMeeting={() => {
                      loadLiveMeeting();
                    }}
                    reloadHomeLearning={() => {
                      checkForLoginAndLoad();
                    }}
                  />
                </Col>
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { onlineLearningSessionObjects } = state.onlineLearningSession;
  const { onlineLearningTrackingObjects } = state.onlineLearningTracking;
  const { learningActivityObjects } = state.learningActivity;
  const { fileObjects } = state.files;
  const { userObjectTeacher } = state.teacher;
  const { school, schools } = state.school;
  const { mediaCategories } = state.mediaCategories;
  const { categories } = state.learningCategory;
  const { quizObjects } = state.quiz;
  const { userObjectKid } = state.kid;
  const { groupMapObjects } = state.groupMap;
  const { reminderObjects } = state.reminder;
  const { liveMeetingObjects } = state.liveMeeting;

  return {
    learningActivityObjects,
    onlineLearningSessionObjects,
    onlineLearningTrackingObjects,
    fileObjects,
    userObjectTeacher,
    school,
    schools,
    mediaCategories,
    categories,
    quizObjects,
    userObjectKid,
    groupMapObjects,
    reminderObjects,
    liveMeetingObjects,
  };
};

export default withTranslation()(
  withRouter(
    connect(mapStatetoProps, {
      getOnlineLearningSessionForKidIds,
      getLearningActivitiesForIds,
      getFilesForFolderIds,
      getTeacherForObjectId,
      getSchoolForObjectId,
      getSchoolsForInstituteId,
      getMediaCategoriesForTargetAudienceIds,
      getCategoriesForCurriculumId,
      getLearningActivitiesForCategoryIds,
      getOnlineLearningTrackingsForKidId,
      getQuizForActivityIds,
      getKidForObjectId,
      getGroupMapsForUserId,
      getRemindersForLiveMeeting,
      getLiveMeetingsForKid,
    })(LearningActivityView)
  )
);
