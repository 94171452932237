import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as ReminderHelper from "../../helpers/ParseHelpers/reminderHelper";
import * as actionTypes from "./actionTypes";
import * as ReminderAction from "./actions";

function* getRemindersForLiveMeetingSaga({
  payload: { kidId, classId, schoolId, groupIds, familyId },
}) {
  try {
    const response = yield call(
      ReminderHelper.getRemindersForLiveMeeting,
      kidId,
      classId,
      schoolId,
      groupIds,
      familyId
    );
    yield put(ReminderAction.setLoading(false));

    if (response) {
      yield put(ReminderAction.updateRemindersForLiveMeeting(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetRemindersForLiveMeetingSaga() {
  yield takeEvery(
    actionTypes.GET_REMINDERS_FOR_LIVE_MEETING,
    getRemindersForLiveMeetingSaga
  );
}

function* reminderSaga() {
  yield all([fork(watchGetRemindersForLiveMeetingSaga)]);
}
export default reminderSaga;
