import * as actions from "./actionTypes";

export const setLoadingQuiz = (boolValue) => {
  return {
    type: actions.SET_LOADING_QUIZ,
    payload: { boolValue },
  };
};

export const getQuizForActivityIds = (activityIds) => {
  return {
    type: actions.GET_QUIZ_FOR_ACTIVITY_IDS,
    payload: { activityIds },
  };
};

export const updateQuizForActivityIds = (quizObjects) => {
  return {
    type: actions.UPDATE_QUIZ_FOR_ACTIVITY_IDS,
    payload: { quizObjects },
  };
};
