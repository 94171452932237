import * as actions from "./actionTypes";

export const setLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const getParentForObjectId = (objectId) => {
  return {
    type: actions.GET_PARENT_FOR_OBJECTID,
    payload: { objectId },
  };
};

export const updateUserObjectParent = (userObjectParent) => {
  return {
    type: actions.UPDATE_USER_OBJECT_PARENT,
    payload: { userObjectParent },
  };
};
