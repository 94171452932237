import Parse from "parse";

const getLiveMeetingsForKid = (recipientIds, objectIds) => {
  try {
    return new Promise((resolve, reject) => {
      let currentDate = new Date();
      let startTime = new Date(currentDate.setHours(0, 0, 0, 0));
      let endTime = new Date(currentDate.setHours(23, 59, 59, 999));
      var query = new Parse.Query("LiveMeeting");
      var query1 = new Parse.Query("LiveMeeting");
      query1.containedIn("recipientIds", recipientIds);
      query1.lessThanOrEqualTo("updatedAt", endTime);
      query1.greaterThanOrEqualTo("updatedAt", startTime);
      query1.doesNotExist("isScheduled");
      if (objectIds.length > 0) {
        var query2 = new Parse.Query("LiveMeeting");
        query2.containedIn("objectId", objectIds);
      }
      if (query2) {
        query = Parse.Query.or(query1, query2);
      } else {
        query = query1;
      }
      // query.notEqualTo('isScheduled', true)
      query.descending("updatedAt");
      query.find().then(
        (results) => {
          resolve(results);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export { getLiveMeetingsForKid };
