import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as LiveMeetingHelper from "../../helpers/ParseHelpers/liveMeetingHelper";
import * as actionTypes from "./actionTypes";
import * as LiveMeetingAction from "./actions";

function* getLiveMeetingsForKidSaga({ payload: { recipientIds, objectIds } }) {
  try {
    const response = yield call(
      LiveMeetingHelper.getLiveMeetingsForKid,
      recipientIds,
      objectIds
    );
    yield put(LiveMeetingAction.setLoading(false));

    if (response) {
      yield put(LiveMeetingAction.updateLiveMeetingsForKid(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetLiveMeetingsForKidSaga() {
  yield takeEvery(
    actionTypes.GET_LIVE_MEETINGS_FOR_KID,
    getLiveMeetingsForKidSaga
  );
}

function* liveMeetingSaga() {
  yield all([fork(watchGetLiveMeetingsForKidSaga)]);
}
export default liveMeetingSaga;
