import * as actions from "./actionTypes";

const initialState = {
  userObjectParent: undefined,
  isLoadingParent: true,
};

const Parent = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING:
      state = { ...state, isLoadingParent: action.payload.boolValue };
      break;

    case actions.UPDATE_USER_OBJECT_PARENT:
      state = {
        ...state,
        userObjectParent: action.payload.userObjectParent,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Parent;
