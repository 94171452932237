import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as QuizHelper from "../../helpers/ParseHelpers/quizHelper";
import * as actionTypes from "./actionTypes";
import * as QuizAction from "./actions";

function* getQuizForActivityIdsSage({ payload: { activityIds } }) {
  try {
    const response = yield call(QuizHelper.getQuizForActivityIds, activityIds);
    yield put(QuizAction.setLoadingQuiz(false));

    if (response) {
      yield put(QuizAction.updateQuizForActivityIds(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetQuizForActivityIdsSage() {
  yield takeEvery(
    actionTypes.GET_QUIZ_FOR_ACTIVITY_IDS,
    getQuizForActivityIdsSage
  );
}

function* quizSaga() {
  yield all([fork(watchGetQuizForActivityIdsSage)]);
}
export default quizSaga;
