import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as WebcodeHelper from "../../helpers/ParseHelpers/webcodeHelper";
import * as actionTypes from "./actionTypes";
import * as WebcodeAction from "./actions";

function* saveUUIDToWebCodeSaga({ payload: { uuid } }) {
  try {
    const response = yield call(WebcodeHelper.saveUUIDToWebCode, uuid);
    yield put(WebcodeAction.setLoading(false));

    if (response) {
      yield put(WebcodeAction.updateWebcodeObject(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchSaveUUIDToWebCodeSaga() {
  yield takeEvery(actionTypes.SAVE_UUID_TO_WEBCODE, saveUUIDToWebCodeSaga);
}

function* webcodeSaga() {
  yield all([fork(watchSaveUUIDToWebCodeSaga)]);
}
export default webcodeSaga;
